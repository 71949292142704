//  API
export const REQUEST_POST_DATA = "REQUEST_POST_DATA"
export const RECEIVE_POST_DATA = "RECEIVE_POST_DATA"
// export const RECEIVE_GEO_INFOS = "RECEIVE_GEO_INFOS"
export const RECEIVE_WP_UID = "RECEIVE_WP_UID"
export const RECEIVE_SITEMAP = "RECEIVE_SITEMAP"

//  Device
export const SET_STAGE_DIMENSIONS = "SET_STAGE_DIMENSIONS"
export const SET_TAB_VISIBLE = "SET_TAB_VISIBLE"

//  Firebase
export const SET_FIREBASE_INITIALIZED = "SET_FIREBASE_INITIALIZED"
export const SET_ANALYTICS_READY_ENABLED = "SET_ANALYTICS_READY_ENABLED"

//  Layout
export const SET_LAYOUT_ELEMENT_Y = "SET_LAYOUT_ELEMENT_Y"

//  Routing
export const SHOW_VIDEO_LIGHTBOX = "SHOW_VIDEO_LIGHTBOX"
export const HIDE_VIDEO_LIGHTBOX = "HIDE_VIDEO_LIGHTBOX"
