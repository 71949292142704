// import {scroller} from "react-scroll"
var Scroll = require("react-scroll")
var scroll = Scroll.animateScroll

import * as actionsConsts from "../../data/actionsConsts"

import {debbify, getObjectDeep as god} from "../../data/selectors/helpers" // eslint-disable-line
const debby = (...args) => debbify("ARouting", ...args)

let bodyScrollable = true

export const showVideoLightbox = props => dispatch => {
  debby("showVideoLightbox()", {props})
  dispatch({type: actionsConsts.SHOW_VIDEO_LIGHTBOX, props})

  if (bodyScrollable) {
    document.body.classList.add("hidden-overflow")
    bodyScrollable = false
  }
}

export const hideVideoLightbox = () => dispatch => {
  debby("hideVideoLightbox()")
  dispatch({type: actionsConsts.HIDE_VIDEO_LIGHTBOX})

  if (!bodyScrollable) {
    document.body.classList.remove("hidden-overflow")
    bodyScrollable = true
  }
}

export const scrollToY =
  (y = 0, offset = 0) =>
  () => {
    scroll.scrollTo(y, {duration: 1000, delay: 0, smooth: "easeInOutQuint", offset})
  }
