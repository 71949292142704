import React, {Component} from "react"
import PropTypes from "prop-types"
import isEqual from "lodash/isEqual"
import {connect} from "react-redux"
import {injectIntl} from "react-intl"

import BugReportManager from "../../../data/utils/BugReportManager" // eslint-disable-line

import * as selectors from "../../../data/selectors"
// import * as actions from "../../../data/actions"

import {debbify, getObjectDeep as god} from "../../../data/selectors/helpers"
const debby = (...args) => debbify("SCookiePolicy", ...args) // eslint-disable-line

import CContentContainerWithPadding from "../../../view/components/CContentContainerWithPadding"
import CHeader from "../../../view/components/CHeader"
import CHeaderSpacer from "../../../view/components/CHeaderSpacer"

import {DivMainContent} from "../../../view/components/Styled"

const DEBUG = false && __DEV__

class SCookiePolicy extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    locationPathname: PropTypes.string.isRequired,
    postData: PropTypes.object,
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  render = () => {
    const {postData} = this.props
    const content = god(postData, "content")
    if (!content) {
      return <div />
    }
    const d = {debug: DEBUG.toString()}
    return (
      <div style={{backgroundColor: "white"}}>
        {true && <CHeaderSpacer />}
        <CContentContainerWithPadding>
          <DivMainContent {...d} dangerouslySetInnerHTML={{__html: content}} />
          {false && <pre>{JSON.stringify(postData, null, 2)}</pre>}
        </CContentContainerWithPadding>
        <CHeader screen={"cookie-policy"} />
      </div>
    )
  }
}

const mapStateToProps = (state, props) => ({
  locationPathname: selectors.getLocationPathname(state, props),
  postData: selectors.getPostData(state, props),
})
export default injectIntl(connect(mapStateToProps)(SCookiePolicy))
