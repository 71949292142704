import * as actionsConsts from "../actionsConsts"

export const initialStateLayout = {
  elementsY: {
    //  [screen]: {
    //    [section]: y
    //  }
  },
}

export function layout(state = initialStateLayout, action) {
  switch (action.type) {
    case actionsConsts.SET_LAYOUT_ELEMENT_Y: {
      const {screen, section, value} = action
      return {
        ...state,
        elementsY: {
          ...state.elementsY,
          [screen]: {
            // main
            ...(state.elementsY.hasOwnProperty(screen) ? state.elementsY[screen] : {}),
            [section]: value,
          },
        },
      }
    }
    default:
      return state
  }
}
