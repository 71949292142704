import React, {Component} from "react"
import PropTypes from "prop-types"
import isEqual from "lodash/isEqual"
import {createSelector} from "reselect" // eslint-disable-line
import styled, {css, ThemeProvider} from "styled-components" // eslint-disable-line
import {Link} from "react-router-dom"

import * as selectors from "../../data/selectors"

import CLogo from "./CLogo"

const DEBUG = false && __DEV__
import {debbify, getObjectDeep as god} from "../../data/selectors/helpers" // eslint-disable-line
const debby = (...args) => debbify("CHeader", ...args)

class CHeader extends Component {
  static propTypes = {
    color: PropTypes.string,
    screen: PropTypes.oneOf(["main", "cookie-policy"]),
    height: PropTypes.number,
    heightSm: PropTypes.number,
  }
  static defaultProps = {
    color: "white",
    height: 80,
    heightSm: 60,
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  render = () => {
    const {color, screen} = this.props
    const d = {debug: DEBUG.toString()}
    debby("render()", {color, screen})
    return (
      <ThemeProvider theme={{}}>
        <Container {...d}>
          <PaddedContainer {...d} paddingVariant={"header"}>
            <Link to={"/"}>
              <CLogo screen={screen} />
            </Link>
          </PaddedContainer>
        </Container>
      </ThemeProvider>
    )
  }
}

const Container = styled.div`
  position: fixed;
  // position: absolute;
  left: 0;
  top: 0;
  right: 0;
  ${props =>
    props.debug == "true" &&
    css`
      background-color: rgba(0, 0, 255, 0.5);
    `}

  display: flex;
  align-items: center;
  justify-content: flex-end;
  overflow: visible;
  pointer-events: none;

  height: ${CHeader.defaultProps.height}px;
  transition: height 0.5s;
  ${props => css`
    ${props.theme.media.smdown} {
      height: ${CHeader.defaultProps.heightSm}px;
    }
  `}
`

const PaddedContainer = styled.div`
  position: relative;
  margin: 0;
  padding: 0;
  ${props => false && selectors.getPaddingLeftRightByThemeAndVariant(props.theme, props.paddingVariant)}
  border: ${props => (props.debug == "true" ? 1 : 0)}px solid yellow;

  margin-right: 30px;
  ${props => css`
    ${props.theme.media.smdown} {
      margin-right: 20px;
    }
  `}
`
export default CHeader
