import React, {Component} from "react"
import PropTypes from "prop-types"
import isEqual from "lodash/isEqual"
import {createSelector} from "reselect" // eslint-disable-line
import styled, {css, ThemeProvider} from "styled-components" // eslint-disable-line

import IconFacebook from "../../images/svg/facebook.svg"
import IconInstagram from "../../images/svg/instagram.svg"
import IconTwitter from "../../images/svg/twitter.svg"
import IconVimeo from "../../images/svg/vimeo.svg"

import * as selectors from "../../data/selectors"

const DEBUG = false && __DEV__
import {debbify, getObjectDeep as god} from "../../data/selectors/helpers" // eslint-disable-line
const debby = (...args) => debbify("CSocialIcons", ...args)

class CSocialIcons extends Component {
  static propTypes = {
    color: PropTypes.string,
    screen: PropTypes.oneOf(["main", "cookie-policy"]),
    urlFacebook: PropTypes.string,
    urlInstagram: PropTypes.string,
    urlTwitter: PropTypes.string,
    urlVimeo: PropTypes.string,
    horizontal: PropTypes.bool,
    showFacebook: PropTypes.bool,
  }
  static defaultProps = {
    color: "white",
    horizontal: false,
    showFacebook: false,
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  render = () => {
    const {color, screen, horizontal, showFacebook} = this.props
    const {urlFacebook, urlInstagram, urlTwitter, urlVimeo} = this.props
    const d = {debug: DEBUG.toString()}
    debby("render()", {color, screen})
    return (
      <ThemeProvider theme={{}}>
        <Container {...d} horizontal={JSON.stringify(horizontal)}>
          <PaddedContainer {...d} paddingVariant={"projects"} horizontal={JSON.stringify(horizontal)}>
            <IconsContainer {...d} horizontal={JSON.stringify(horizontal)}>
              {showFacebook && (
                <IconContainer {...d} target={"_blank"} href={urlFacebook}>
                  <IconImage {...d} src={IconFacebook} />
                </IconContainer>
              )}
              <IconContainer {...d} target={"_blank"} href={urlInstagram}>
                <IconImage {...d} src={IconInstagram} scale={"0.8"} />
              </IconContainer>
              <IconContainer {...d} target={"_blank"} href={urlTwitter}>
                <IconImage {...d} src={IconTwitter} scale={"0.8"} />
              </IconContainer>
              <IconContainer {...d} target={"_blank"} href={urlVimeo}>
                <IconImage {...d} src={IconVimeo} scale={"0.8"} />
              </IconContainer>
            </IconsContainer>
          </PaddedContainer>
        </Container>
      </ThemeProvider>
    )
  }
}

const Container = styled.div`
  ${props =>
    props.debug == "true" &&
    css`
      background-color: rgba(0, 255, 255, 0.5);
    `}

  display: flex;
  align-items: center;

  transition: height 0.5s;
  pointer-events: none;

  ${props =>
    props.horizontal == "false" &&
    css`
      position: fixed;
      left: 0;
      bottom: ${30 + 70}px;
      right: 25px;
      max-width: ${props => props.theme.vars.maxWidth.container}px;
      margin: 0 auto;
      ${props.theme.media.smdown} {
        display: none;
      }
      ${props.theme.media.xl} {
        right: 15px;
      }
      ${props.theme.media.lg} {
        right: 10px;
      }
    `}

  ${props =>
    props.horizontal == "true" &&
    css`
      margin-top: 70px;
      position: relative;
      flex-direction: row;
      position: relative;
      width: 100%;
    `}
`

const PaddedContainer = styled.div`
  position: relative;
  width: 100%;
  margin: 0;
  padding: 0;
  ${props => false && selectors.getPaddingLeftRightByThemeAndVariant(props.theme, props.paddingVariant)}
  border: ${props => (props.debug == "true" ? 1 : 0)}px solid yellow;

  ${props =>
    props.horizontal == "false" &&
    css`
      ${props.theme.media.smdown} {
        margin-right: 20px;
      }
    `}

  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

  ${props =>
    props.horizontal == "true" &&
    css`
      flex: 1;
      width: 100%;
      flex-direction: row;
      margin-right: 0px;
      justify-content: flex-start;
      ${props.theme.media.smdown} {
        justify-content: center;
      }
    `}
`

const IconsContainer = styled.div`
  border: ${props => (props.debug == "true" ? 1 : 0)}px solid blue;

  ${props =>
    props.horizontal == "true" &&
    css`
      display: flex;
      flex-direction: row;
      align-items: flex-start;
    `}
`

const IconContainer = styled.a`
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: ${props => (props.debug == "true" ? 1 : 0)}px solid red;
  pointer-events: all;

  transition: all 0.1s ease-out;
  :hover {
    transform: scale(1.2, 1.2);
  }
`

const IconImage = styled.img`
  // width: 30px;
  height: ${props => (props.hasOwnProperty("scale") ? parseFloat(props.scale) : 1) * 30}px;
  object-fit: contain;
  filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.3));
`

export default CSocialIcons
