import {push, replace} from "connected-react-router" // eslint-disable-line

let firebase = null

import {CONFIG} from "../../config"
import * as actionsConsts from "../actionsConsts"
// import {getObjectDeep as god} from "../../data/selectors/helpers"

import BugReportManager from "../../data/utils/BugReportManager"

import {requestWpUid} from "./AAPI"
import {setAnalyticsReady} from "./AAnalytics"
// import {setLoaderVisible, showDialogConfirmCancellation, showDialogConfirmLogout, showDialogVerifyPhoneNumberFailed, showDialogConfirmFlagAsNoShow, showDialogConfirmFlagAsShow, showDialogConfirmRemoveFlag} from "./AUserInteraction"

import {debbify} from "../../data/selectors/helpers"
const debby = (...args) => debbify("AFirebase", ...args)

const loadFirebase = async () => {
  firebase = await import("firebase/app") // eslint-disable-line
  await import("firebase/analytics")
  firebaseLoaded = true
}

let firebaseLoaded = false
// let resolvedOnce = false // login
export const initFirebase = () => async (dispatch, getState) => {
  debby("initFirebase()..")
  const {initialized} = getState().firebase
  if (initialized) {
    console.warn("Firebase already initialized.")
    return
  }

  await loadFirebase()

  try {
    firebase.initializeApp(CONFIG.firebase.config)
  } catch (error) {
    BugReportManager.captureException(error)
  }

  dispatch({type: actionsConsts.SET_FIREBASE_INITIALIZED})

  const wpUid = await dispatch(requestWpUid())

  if (__DEV__) {
    if (!CONFIG.firebase.useAnalyticsInDev) {
      dispatch(setAnalyticsReady({enabled: false, reason: "disabled-in-dev-mode"}))
    } else {
      dispatch(setAnalyticsReady({enabled: true, reason: "enabled-in-dev-mode"}))
    }
  } else if (!!wpUid) {
    if (!CONFIG.firebase.useAnalyticsForAdmins) {
      dispatch(setAnalyticsReady({enabled: false, reason: "disabled-for-admin"}))
    } else {
      dispatch(setAnalyticsReady({enabled: false, reason: "enabled-for-admins"}))
    }
  } else {
    dispatch(setAnalyticsReady({enabled: true, reason: "regular-user"}))
  }

  debby("initFirebase() done.")
}

const wait = (delayMs = 250) => new Promise(resolve => setTimeout(resolve, delayMs))

export const waitUntilFirebaseLoaded = async () => {
  while (!firebaseLoaded) {
    await wait(50)
  }
  return firebase
}

export const waitUntilAnalyticsReady = (source = "") => async (dispatch, getState) => {
  await waitUntilFirebaseLoaded()
  const enabled = getState().firebase.analyticsEnabled
  if (enabled) {
    while (!getState().firebase.analyticsReady) {
      debby("waitUntilAnalyticsReady(): Waiting..", {source})
      await wait(2000)
    }
    firebase.analytics()
  }
  return {firebase, enabled}
}
