// DEFINED IN APP - MUST be pure functions without any libraries
// also used in:
//  - functionsLocal
//  - functions
//  - bot

const objectPath = require("object-path")

export const getHelpersPureAvailable = () => true

export const getObjectDeep = (obj, pathRaw, fallback = null) => {
  let path = pathRaw.split("/").join(".")
  let value = objectPath.get(obj, path)
  if (value == undefined) {
    return fallback
  }
  return value
}

export const getHashFromStringPure = (md5Obj, string, maxLength = 4) => {
  const stringMd5 = md5Obj(string)
  const replacesLowerCase = {
    // replace hard-to-read characters
    ["o"]: "",
    ["0"]: "",
    ["i"]: "",
    ["1"]: "",
  }
  let firstChars = stringMd5.toLowerCase()
  for (const search of Object.keys(replacesLowerCase)) {
    firstChars = firstChars.split(search).join(replacesLowerCase[search])
  }
  firstChars = firstChars.substr(0, maxLength)
  const firstCharsUpper = firstChars.toLowerCase()
  return firstCharsUpper
}

export const getObjectSortedPure = (isObjectFunc, obj) =>
  Object.keys(obj)
    .sort()
    .reduce((result, key) => {
      const value = obj[key]
      if (isObjectFunc(value)) {
        result[key] = getObjectSortedPure(isObjectFunc, value)
      } else {
        result[key] = obj[key]
      }
      return result
    }, {})

export const getPrefixReadable = prefix => {
  if (!prefix) {
    return ""
  }
  let ret = prefix
  if (ret.charAt(0) != "+") {
    ret = "+" + ret
  }
  return ret
}

export const getPrefixRaw = prefix => {
  if (!prefix) {
    return ""
  }
  const charsAllowed = "0123456789"
  return prefix
    .split("")
    .filter(d => charsAllowed.indexOf(d) != -1)
    .join("")
}

export const getPhoneNumberFirebase = phoneNumber => {
  if (!phoneNumber) {
    return null
  }
  let ret = getPrefixRaw(phoneNumber)
  if (ret.charAt(0) != "+") {
    ret = "+" + ret
  }
  return ret
}

export const getPropsByScoreKey = scoreKey => {
  let dayString = undefined
  let resKey = undefined
  if (!!scoreKey && !!scoreKey.split("_").length) {
    dayString = scoreKey.split("_")[0] // 2021-02-21
    resKey = scoreKey.substr(dayString.length + 1)
  }
  return {dayString, resKey}
}

export const getDisplayNameSanified = displayName => {
  if (!displayName) {
    return ""
  }
  return displayName.trim()
}

export const getResKeyData = resKey => {
  const resKeyParts = resKey.split("_") // "1000_1050_i_t1" -> ["1000", "1050", "i", "1"]
  const timeStart = resKeyParts[0].substr(0, 2) + ":" + resKeyParts[0].substr(2, 2) // 10:00
  const timeEnd = resKeyParts[1].substr(0, 2) + ":" + resKeyParts[1].substr(2, 2) // 10:50
  const areaShortKey = resKeyParts[2]
  const tableKey = resKeyParts[3]
  return {timeStart, timeEnd, areaShortKey, tableKey}
}

export const getAreaKeyByShortKeyPure = (configAvailableAreas, areaShortKeySelected) => {
  let areaKey = null
  if (!!configAvailableAreas && !!Object.keys(configAvailableAreas).length) {
    for (const areaKeyCheck of Object.keys(configAvailableAreas)) {
      if (configAvailableAreas[areaKeyCheck].shortKey == areaShortKeySelected) {
        areaKey = areaKeyCheck
      }
    }
  }
  return areaKey
}

export const getFirstCapital = (string, changeAfter = true) => {
  if (!string || string.length == 0) {
    return ""
  }
  const stringNew = string.substr(0, 1).toUpperCase() + (changeAfter ? string.substr(1).toLowerCase() : string.substr(1))
  return stringNew
}

export const getEmailIsValid = email => {
  // var regex = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()\.,;\s@\"]+\.{0,1})+[^<>()\.,;:\s@\"]{2,})$/; // eslint-disable-line
  // let regex = new RegExp(/([\w\.\-_]+)?\w+@[\w-_]+(\.\w+){1,}/,'igm') // eslint-disable-line
  var regex = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i // eslint-disable-line
  var valid = regex.test(email)
  return valid
}

export const getTwoDigits = input => (`${input}`.length == 1 ? `0${input}` : `${input}`.substr(0, 2))

export const getTimeStringByMinutes = minutes => {
  const hours = Math.floor(minutes / 60)
  const mins = minutes - hours * 60
  return getTwoDigits(hours) + ":" + getTwoDigits(mins)
}

export const getMinutesByTimeString = timeString => {
  if (!!timeString) {
    const parts = timeString.split(":")
    if (parts.length == 2) {
      return parseInt(parts[0]) * 60 + parseInt(parts[1])
    }
  }
  return 0
}

export const getIntlIdForDaysAdded = daysAdded => {
  if (daysAdded == 0) {
    return "DayRelativeToday"
  } else if (daysAdded == 1) {
    return "DayRelativeTomorrow"
  } /* else if (daysAdded == 2) {
    return "DayRelativeInTwoDays"
  }*/
  return null
}

export const getAreaByShortKey = (areas, areaShortKey) => {
  let area = null
  for (const areaKey of Object.keys(areas)) {
    if (areas[areaKey].shortKey == areaShortKey) {
      area = {...areas[areaKey], key: areaKey}
    }
  }
  return area
}

export const getArrayUnique = array => (!array || !array.length ? [] : array.filter((value, index, self) => self.indexOf(value) === index).filter(i => !!i)) // only unique

export const getWindowLocationPathnameCleanForAnalytics = (windowLocationPathname, folderReact) => {
  if (!windowLocationPathname) {
    return "/"
  }
  let path = windowLocationPathname.trim()
  path = path.split(folderReact).join("/")
  while (path.indexOf("//") != -1) {
    path = path.replace("//", "/")
  }
  if (path == "") {
    path == "/"
  }
  if (path == "/") {
    return "/"
  }
  while (path.charAt(path.length - 1) == "/") {
    path = path.substr(0, path.length - 1)
  }
  return path
}

export const getSlotsForDayPure = ({uid, reservationsForDayString, areaShortKey: areaShortKeyParams, slots: slotsParams, areas: areasParams, reservableKey: reservableKeyParams} = {}) => {
  const god = getObjectDeep

  if (!slotsParams) {
    console.error("getSlotsForDay(): Missing 'slotsParams'.")
    return []
  }
  const reservables = god(slotsParams, "reservables") // {default: [{<key>, <timeRange>}]}
  if (!reservables) {
    return []
  }

  let slotsForDay = []
  let debug = {}

  if (!reservableKeyParams) {
    console.error("getSlotsForDay(): Missing param 'reservableKey'!")
  }

  const reservableKeySelected = reservableKeyParams

  debug = {...debug, reservableKeySelected}

  let debugSlots = []
  let area = null

  slotsForDay = reservables[reservableKeySelected]
    .map(slot => {
      const {key, timeRange} = slot // eslint-disable-line
      const slotTimeStart = timeRange.split("-")[0]
      const slotTimeEnd = timeRange.split("-")[1]
      const slotMinutesStart = getMinutesByTimeString(slotTimeStart)
      const slotMinutesEnd = getMinutesByTimeString(slotTimeEnd)

      let slotIsReservedByUser = false

      area = getAreaByShortKey(areasParams, areaShortKeyParams)
      if (!!area) {
        let tablesAvailable = area.tables.filter(t => !t.hasOwnProperty("available") || t.available == true)

        let debugSlot = {...slot}

        let debugSlotReservations = {}
        if (!!reservationsForDayString) {
          for (const resKey of Object.keys(reservationsForDayString)) {
            const {uid: resUid} = reservationsForDayString[resKey]
            const {timeStart: resTimeStart, timeEnd: resTimeEnd, areaShortKey: resAreaShortKey, tableKey: resTableKey} = getResKeyData(resKey)
            const resMinutesStart = getMinutesByTimeString(resTimeStart)
            const resMinutesEnd = getMinutesByTimeString(resTimeEnd)

            if (resAreaShortKey == areaShortKeyParams) {
              // matches area

              const slotTimeStartMatches = resMinutesStart <= slotMinutesStart && slotMinutesStart < resMinutesEnd
              const slotTimeEndMatches = resMinutesStart <= slotMinutesEnd - 1 && slotMinutesEnd < resMinutesEnd - 1

              debugSlotReservations = {resKey, slotTimeStart, slotTimeEnd, slotTimeStartMatches, slotTimeEndMatches}

              if (slotTimeStartMatches || slotTimeEndMatches) {
                // there's already a reservation for this area/table/slot
                tablesAvailable = tablesAvailable.filter(table => table.key != resTableKey) // remove from available tables
                if (resUid == uid) {
                  slotIsReservedByUser = true
                }
              }
            }
          }
        }
        debugSlot = {...debugSlot, debugSlotReservations}

        const slotIsAvailable = tablesAvailable.length > 0

        debugSlots.push(debugSlot)

        return {
          available: slotIsAvailable,
          reservedByUser: slotIsReservedByUser,
          timeStart: getTimeStringByMinutes(slotMinutesStart),
          timeEnd: getTimeStringByMinutes(slotMinutesEnd),
          tablesKeys: tablesAvailable.map(t => t.key),
          // _timeEndFull: getTimeStringByMinutes(slotMinutesEnd),
        }
      }
    })
    .filter(i => !!i)

  debug = {...debug, debugSlots}
  // if (dayString == "2021-02-21") { debugger } // prettier-ignore
  return {slotsForDay, area, debug}
}

export const getRandomPin = ({length = 4, numbersOnly = false}) => {
  var result = ""
  // var characters = numbersOnly ? "1234567890" : "23456789abdefghkp"
  // var characters = numbersOnly ? "1234567890" : "curtsmafied2"
  var characters = numbersOnly ? "1234567890" : "cursmafie284"
  var charactersLength = characters.length
  for (var i = 0; result.length < length; i++) {
    let nextChar = characters.charAt(Math.floor(Math.random() * charactersLength))
    let lastChar = result.length > 0 ? result.charAt(result.length - 1) : "X" // not existing char
    let prevLastChar = result.length > 1 ? result.charAt(result.length - 2) : "X" // not existing char
    if (nextChar == lastChar) {
      // do nothing
    } else if (nextChar == prevLastChar) {
      // do nothing
    } else {
      result += nextChar
    }
  }
  return result
}

export const wait = (ms = 1000) => new Promise(resolve => setTimeout(resolve, ms))

// export consts will be transformed to module.exports via shared-code.js
