import {CONFIG} from "../config"

const {breakpoints, colors} = CONFIG.layout
const basicTheme = {
  breakpoints,
  media: {
    xl: `@media only screen and (min-width: ${breakpoints.xl.min}px)`,
    lg: `@media only screen and (min-width: ${breakpoints.lg.min}px) and (max-width: ${breakpoints.xl.min - 1}px)`,
    md: `@media only screen and (min-width: ${breakpoints.md.min}px) and (max-width: ${breakpoints.lg.min - 1}px)`,
    sm: `@media only screen and (min-width: ${breakpoints.sm.min}px) and (max-width: ${breakpoints.md.min - 1}px)`,
    xs: `@media only screen and (max-width: ${breakpoints.sm.min - 1}px)`,

    lgdown: `@media only screen and (max-width: ${breakpoints.xl.min - 1}px)`,
    mddown: `@media only screen and (max-width: ${breakpoints.lg.min - 1}px)`,
    smdown: `@media only screen and (max-width: ${breakpoints.md.min - 1}px)`,

    landscape: `@media (orientation: landscape)`,
    portrait: `@media (orientation: portrait)`,
  },
  vars: {
    maxWidth: {
      container: 1600,
    },
    padding: {
      container: 20,
      horizontal: {
        header: {
          xs: 30,
          sm: 40,
          md: 80,
          lg: 100,
          xl: 125,
        },
        content: {
          xs: 55,
          sm: 80,
          md: 100,
          lg: 130,
          xl: 180,
        },
        projects: {
          xs: 55,
          sm: 80,
          md: 90,
          lg: 90,
          xl: 180,
        },
        teammembers: {
          xs: 10,
          sm: 50,
          md: 80,
          lg: 90,
          xl: 180,
        },
      },
    },
    fonts: {
      families: {
        default: "Geomanist", // TODO: Remove
      },
      weights: {
        default: 400, // <- default here
        light: 300,
        regular: 400,
        medium: 500,
        semibold: 600,
        bold: 700,
        extrabold: 800,
        black: 900,
      },
    },
    colors,
    transitions: {
      themeTransitionDuration: CONFIG.layout.waypointThemeTransitionDuration,
    },
  },
  colorBg: "rgba(0,0,0,0.5)", // TODO: Remove
  colorBgSpecial: "yellow",
  colorBgSpecial2: "blue",
}

export {basicTheme}
