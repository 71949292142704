import React, {Component} from "react"
import PropTypes, {instanceOf} from "prop-types"
import isEqual from "lodash/isEqual"
import {connect} from "react-redux"
import {injectIntl} from "react-intl"
import {push} from "connected-react-router"
import styled, {css, ThemeProvider} from "styled-components" // eslint-disable-line
// import {Link} from "react-router-dom"
import {withCookies, Cookies} from "react-cookie"

const DEBUG = false && __DEV__
import {debbify, getObjectDeep as god} from "../../data/selectors/helpers"
const debby = (...args) => debbify("CCookiePopup", ...args)

const COOKIE_ACCEPTED_NAME = "acceptedPolicy"

class CCookiePopup extends Component {
  static propTypes = {
    intl: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    cookiesAccepted: PropTypes.bool,
    cookies: instanceOf(Cookies).isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      draw: false,
      acceptedClicked: false,
    }
  }

  componentDidMount = () => {
    if (DEBUG) {
      this.resetCookieTimeoutId = setTimeout(() => {
        debby(`componentDidMount(): Resetting cookie '${COOKIE_ACCEPTED_NAME}'.`)
        this.props.cookies.set(COOKIE_ACCEPTED_NAME, JSON.stringify(false), {path: "/"})
      }, 1500)
    }
    this.drawTimeoutId = setTimeout(() => {
      this.setState({draw: true})
    }, 3000)
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  componentWillUnmount = () => {
    clearTimeout(this.resetCookieTimeoutId)
    clearTimeout(this.drawTimeoutId)
    clearTimeout(this.handleAcceptTimeoutId)
  }

  onButtonAcceptClick = e => {
    !!e && e.preventDefault()
    this.setState({acceptedClicked: true})
    this.handleAcceptTimeoutId = setTimeout(() => {
      debby("onButtonAcceptClick()", {}, true)
      this.props.cookies.set(COOKIE_ACCEPTED_NAME, JSON.stringify(true), {path: "/"})
    }, 500)
  }

  onButtonShowPolicyClick = () => this.props.dispatch(push("/cookie-policy"))

  render = () => {
    const {draw, acceptedClicked} = this.state // eslint-disable-line
    const {intl, cookies, cookiesAccepted} = this.props // eslint-disable-line
    const d = {debug: DEBUG.toString()}
    const shown = !cookiesAccepted && draw
    debby("render()", {cookiesAccepted, draw, shown})
    return (
      <ThemeProvider theme={{}}>
        <Container {...d} shown={JSON.stringify(shown)} handlePointerEvents={JSON.stringify(!acceptedClicked && shown)}>
          <PopupContainer {...d}>
            <MainText {...d}>{intl.formatMessage({id: `CookiePolicyText`})}</MainText>
            <ButtonContainer {...d} shown={shown}>
              <Button {...d} onClick={this.onButtonAcceptClick} bold>
                {intl.formatMessage({id: `CookiePolicyButtonAcceptLabel`})}
              </Button>
              <Button {...d} onClick={this.onButtonShowPolicyClick}>
                {intl.formatMessage({id: `CookiePolicyButtonShowPolicyLabel`})}
              </Button>
            </ButtonContainer>
          </PopupContainer>
        </Container>
      </ThemeProvider>
    )
  }
}

const Container = styled.div`
  position: fixed;
  border: ${props => (props.debug == "true" ? 2 : 0)}px solid red;
  // background-color: white;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;

  z-index: ${props => (props.shown == "true" ? 30 : 0)};
  opacity: ${props => (props.shown == "true" ? 1 : DEBUG ? 0.5 : 0)};
  // pointer-events: ${props => (props.handlePointerEvents == "true" ? "auto" : "none")};
  transition: all 0.5s ease-out;
`

const PopupContainer = styled.div`
  max-width: 500px;
  // background-color: #392c56;
  // color: white;
  background-color: ${props => props.theme.vars.colors.fixedProjectBg};
  color: black;
  pointer-events: all;

  border: ${props => (props.debug == "true" ? 2 : 0)}px solid green;
  font-size: 14px;
  line-height: 1.3;
  border-radius: 2px;
`

const MainText = styled.div`
  padding: 10px 20px 10px 20px;
  text-align: center;
`

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${props => (props.acceptedClicked ? 0.6 : 1)};
`
const Button = styled.div`
  border: ${props => (props.debug == "true" ? 2 : 0)}px solid blue;
  padding: 10px;
  margin: 0px 10px 0px 10px;
  cursor: pointer;
  ${props =>
    props.bold &&
    css`
      font-weight: ${props.theme.vars.fonts.weights.bold};
    `}
`

/*
const FooterContent = styled.div`
  padding: 20px 0px;
  border: ${props => (props.debug == "true" ? 2 : 0)}px solid green;
  display: flex;
`

const ColLeft = styled.div`
  flex: 1;
  border: ${props => (props.debug == "true" ? 2 : 0)}px solid blue;
`

const ColRight = styled.div`
  flex: 1;
  border: ${props => (props.debug == "true" ? 2 : 0)}px solid brown;
  text-align: right;
`
*/
const mapStateToProps = (state, props) => ({
  cookiesAccepted: god(props.cookies, `cookies.${COOKIE_ACCEPTED_NAME}`, "false") == "true",
})
export default withCookies(injectIntl(connect(mapStateToProps)(CCookiePopup)))
