import React, {Component} from "react"
import PropTypes from "prop-types"
import isEqual from "lodash/isEqual"
import styled, {css, ThemeProvider} from "styled-components"
import ReactPlayer from "react-player"

import IconCross from "../../images/svg/cross.svg"
import IconArrow from "../../images/svg/arrow.svg"

const DEBUG = false && __DEV__
import {debbify, getObjectDeep as god} from "../../data/selectors/helpers"
const debby = (...args) => DEBUG && debbify("CVideoLightbox", ...args)

class CVideoLightbox extends Component {
  static propTypes = {
    shown: PropTypes.bool,
    config: PropTypes.shape({
      mode: PropTypes.oneOf(["vimeo"]),
      slug: PropTypes.string,
      allItems: PropTypes.array,
    }),
    enablePlayback: PropTypes.bool,
    onHideRequest: PropTypes.func,
    onNextRequest: PropTypes.func,
  }
  static defaultProps = {}

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  onBackgroundClick = () => {
    const {onHideRequest} = this.props
    !!onHideRequest && onHideRequest()
  }

  onNextClick = () => {
    const {onNextRequest} = this.props
    const indexInAll = this.getIndexInAllItems()
    if (indexInAll == -1) {
      return
    }
    const nextIndex = (indexInAll + 1) % this.props.config.allItems.length
    const project = this.props.config.allItems[nextIndex]
    if (!project) {
      return
    }
    const vimeoUrl = project.video.vimeoUrl
    const title = project.title
    const slug = project.slug
    !!onNextRequest && onNextRequest({mode: "vimeo", slug, url: vimeoUrl, title, allItems: this.props.config.allItems})
  }

  getIndexInAllItems = () => {
    const {config} = this.props
    const {slug, allItems} = config || {}
    const indexInAll = !!slug && !!allItems && !!allItems.length ? allItems.findIndex(item => item.slug == slug) : -1
    return indexInAll
  }

  render = () => {
    const {shown, config, enablePlayback} = this.props
    // const {title} = config || {}
    const d = {debug: DEBUG.toString()}
    const indexInAll = this.getIndexInAllItems()
    const project = indexInAll != -1 && !!config && !!config.allItems && !!config.allItems.length && config.allItems.length > indexInAll ? config.allItems[indexInAll] : null

    debby("render()", {shown, project, indexInAll, enablePlayback, ...(config || {})})
    const title = god(project, "title")
    const vimeoUrl = god(project, "video.vimeoUrl")
    const description = god(project, "description")
    const participants = god(project, "participants", [])

    const logos = god(project, "logos", [])

    const participantsMembers = participants.map((p, i) => `<nobr>${p.title} – ${p.role}</nobr>${i < participants.length - 1 ? "" : ""}`).join("<br/>") //.join(" / ")
    return (
      <ThemeProvider theme={{}}>
        <Container {...d} shown={shown.toString()}>
          {shown && (
            <ContentContainer {...d}>
              <ContentFloating {...d}>
                {/* Title ///////////////////////////////////////////////////////////////////////// */}
                <TitleContainer {...d}>{title}</TitleContainer>

                {/* Player & Control Icons //////////////////////////////////////////////////////// */}
                <PlayerAndControlsContainer {...d}>
                  <PlayerCol {...d}>
                    <Player16to9 {...d}>
                      <StyledReactPlayer
                        //
                        {...d}
                        config={{vimeo: {preload: true}}}
                        url={vimeoUrl}
                        width={"100%"}
                        height={"100%"}
                        light={false}
                        playing={enablePlayback}
                        controls
                      />
                    </Player16to9>
                  </PlayerCol>
                  <ControlsCol {...d}>
                    <IconsContainer {...d}>
                      <IconContainer {...d} onClick={this.onBackgroundClick}>
                        <IconImage src={IconCross} {...d} />
                      </IconContainer>
                      <IconSpacer {...d} />
                      <IconContainer {...d} onClick={this.onNextClick}>
                        <IconImage src={IconArrow} {...d} />
                      </IconContainer>
                    </IconsContainer>
                  </ControlsCol>
                </PlayerAndControlsContainer>

                {/* Description /////////////////////////////////////////////////////////////////// */}
                {!!description && true && (
                  <DescriptionContainer
                    //
                    {...d}
                    dangerouslySetInnerHTML={{__html: description}}
                  />
                )}

                {/* Crew ////////////////////////////////////////////////////////////////////////// */}
                {!!participants && !!participants.length && true && (
                  <ParticipantsContainer {...d}>
                    <ParticipantsTeam>Crew</ParticipantsTeam>
                    <ParticipantsMembers dangerouslySetInnerHTML={{__html: participantsMembers}} />
                  </ParticipantsContainer>
                )}

                {/* Logos ///////////////////////////////////////////////////////////////////////// */}
                {!!logos && !!logos.length && (
                  <LogosContainer {...d}>
                    {logos.map((item, i) => {
                      return (
                        <LogoContainer {...d} key={`logo_${i}`} scale={god(item, "scale")}>
                          <LogoImage {...d} src={god(item, ["sizes", "home-logo-md", "url"])} scale={god(item, "scale")} />
                        </LogoContainer>
                      )
                    })}
                  </LogosContainer>
                )}
              </ContentFloating>
            </ContentContainer>
          )}
        </Container>
      </ThemeProvider>
    )
  }
}

const Container = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100%;

  background-color: rgba(0, 0, 0, 0.95);

  transition: all 0.5s;
  opacity: 0;
  pointer-events: none;
  ${props =>
    props.shown == "true" &&
    css`
      opacity: 1;
      // pointer-events: auto;
    `}

  border: ${props => (props.debug == "true" ? 1 : 0)}px solid green;

  ${props => css`
    ${props.theme.media.smdown} {
      padding: 10px;
    }
  `}
`

const ContentContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  max-width: ${props => props.theme.vars.maxWidth.container}px;
  margin: 0 auto;
  height: 100%;

  border: ${props => (props.debug == "true" ? 1 : 0)}px solid blue;
  overflow-y: scroll;
  -webkit-overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;

  pointer-events: all;
`

const ContentFloating = styled.div`
  position: relative;

  padding-top: 120px;
  padding-bottom: 200px;
  padding-left: 70px; ///// <--- padding from hero
  padding-right: 70px;
  ${props => css`
    ${props.theme.media.smdown} {
      padding-top: 100px;
      padding-bottom: 100px;
      padding-left: 20px;
      padding-right: 20px;
    }
  `}
`

const TitleContainer = styled.div`
  position: relative;
  // position: fixed;
  width: 100%;
  color: white;
  font-weight: 900;
  border: ${props => (props.debug == "true" ? 1 : 0)}px solid white;

  font-size: 48px;
  padding-bottom: 30px;
  ${props => css`
    ${props.theme.media.smdown} {
      font-size: 32px;
      padding-bottom: 20px;
    }
  `}
`

const PlayerAndControlsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  color: white;
`

const PlayerCol = styled.div`
  flex: 1;
  border: ${props => (props.debug == "true" ? 1 : 0)}px solid white;
`

const ControlsCol = styled.div`
  position: relative;
  border: ${props => (props.debug == "true" ? 1 : 0)}px solid blue;

  width: 200px;
  ${props => css`
    ${props.theme.media.md} {
      width: 120px;
    }
  `}
  ${props => css`
    ${props.theme.media.smdown} {
      width: 80px;
    }
  `}
`

const Player16to9 = styled.div`
  width: 100%;
  padding-top: ${100 / (16 / 9)}%;
  height: auto;
  position: relative;
`

const DescriptionContainer = styled.div`
  position: relative;
  color: white;
  // line-height: 32px;
  font-weight: 300;

  border: ${props => (props.debug == "true" ? 1 : 0)}px solid red;

  padding-top: 60px;
  padding-right: 200px; // same as icons col
  font-size: 30px;
  > p {
    padding-top: 30px;
  }
  ${props => css`
    ${props.theme.media.mddown} {
      padding-top: 20px;
      padding-right: 0px;
      font-size: 25px;
      > p {
        padding-top: 20px;
      }
    }
  `}
  ${props => css`
    ${props.theme.media.xs} {
      padding-top: 20px;
      padding-right: 0px;
      font-size: 20px;
      > p {
        padding-top: 20px;
      }
    }
  `}
`

const ParticipantsContainer = styled.div`
  border: ${props => (props.debug == "true" ? 1 : 0)}px solid green;

  padding-top: 60px;
  padding-right: 200px; // same as icons col
  ${props => css`
    ${props.theme.media.mddown} {
      padding-top: 60px;
      padding-right: 0px;
    }
  `}
`

const ParticipantsTeam = styled.div`
  color: white;
  font-weight: bold;
  font-size: 24px;
  padding-bottom: 10px;
`

const ParticipantsMembers = styled.div`
  color: white;
  font-weight: 300;
  font-size: 24px;
  line-height: 26px;

  ${props => css`
    ${props.theme.media.xs} {
      font-size: 20px;
    }
  `}// padding-bottom: 10px;
`

const LogosContainer = styled.div`
  padding-top: 60px;
  color: white;
  border: ${props => (props.debug == "true" ? 1 : 0)}px solid blue;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 200px; // same as icons col

  ${props => css`
    ${props.theme.media.mddown} {
      padding-right: 0px;
    }
  `}
`
const LogoContainer = styled.div`
  position: relative;
  margin-right: 50px;
  height: ${props => (props.scale || 1) * 80}px;
  border: ${props => (props.debug == "true" ? 1 : 0)}px solid white;
`

const LogoImage = styled.img`
  position: relative;
  height: 100%;
  object-fit: contain;

  // transform: scale(${props => props.scale || 1});
  ${props =>
    props.debug == "true" &&
    false &&
    css`
      background-color: yellow;
    `}
`

const IconsContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  color: white;
  border: ${props => (props.debug == "true" ? 1 : 0)}px solid red;

  padding-left: 70px;
  ${props => css`
    ${props.theme.media.md} {
      padding-left: 30px;
    }
  `}
  ${props => css`
    ${props.theme.media.smdown} {
      padding-left: 20px;
    }
  `}
`

const IconContainer = styled.div`
  display: block;
  position: relative;
  width: 100%;
  height: auto;
  border: ${props => (props.debug == "true" ? 1 : 0)}px solid yellow;
  cursor: pointer; // clickable
  transition: scale 0.3s ease-out;
`

const IconSpacer = styled.div`
  flex: 1;
  align-self: stretch;
  border: ${props => (props.debug == "true" ? 1 : 0)}px solid blue;
`

const IconImage = styled.img`
  width: 100%;
  height: auto;
  object-fit: contain;
`

const StyledReactPlayer = styled(ReactPlayer)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: ${props => (props.debug == "true" ? 1 : 0)}px solid green;
  background-color: ${props => (props.debug == "true" ? "white" : "#222")};

  // margin: auto auto;
  max-height: calc(100vh - 100px);
  pointer-events: none !important;

  iframe {
    position: relative;
    width: 100% !important;
    height: 100% !important;
    pointer-events: auto;
  }
`

export default CVideoLightbox
