import React, {Component} from "react"
import PropTypes from "prop-types"
import isEqual from "lodash/isEqual"
import {injectIntl} from "react-intl"
import styled, {css, ThemeProvider} from "styled-components" // eslint-disable-line
import {Link} from "react-router-dom"

import CContentContainerWithPadding from "./CContentContainerWithPadding"

import * as selectors from "../../data/selectors"

const DEBUG = false && __DEV__
import {debbify} from "../../data/selectors/helpers"
const debby = (...args) => debbify("CFooter", ...args)

class CFooter extends Component {
  static propTypes = {
    intl: PropTypes.object.isRequired,
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  render = () => {
    const {intl} = this.props
    const d = {debug: DEBUG.toString()}
    debby("render()")
    return (
      <ThemeProvider theme={{}}>
        <Container {...d} paddingVariant={"content"}>
          <FooterContent {...d}>
            <ColLeft {...d}>
              <Link to={"/cookie-policy"}>{intl.formatMessage({id: `FooterLinkCookiePolicy`})}</Link>
            </ColLeft>
            <ColCenter {...d} />
            <ColRight {...d}>
              <span>{intl.formatMessage({id: `FooterAllRightsReserved`}, {year: new Date().getFullYear()})}</span>
            </ColRight>
          </FooterContent>
          <CContentContainerWithPadding></CContentContainerWithPadding>
        </Container>
      </ThemeProvider>
    )
  }
}

const Container = styled.div`
  // width: 100%;
  position: relative;
  overflow: hidden;
  border: ${props => (props.debug == "true" ? 2 : 0)}px solid red;
  // background-color: #c5cac4;
  background-color: black;
  color: white;
  max-width: ${props => props.theme.vars.maxWidth.container}px;
  margin: 0 auto;
  ${props => selectors.getPaddingLeftRightByThemeAndVariant(props.theme, props.paddingVariant)}

  font-size: 18px;
  ${props => css`
    ${props.theme.media.sm} {
      font-size: 13px;
    }
  `}
  ${props => css`
    ${props.theme.media.xs} {
      font-size: 11px;
    }
  `}
`

const FooterContent = styled.div`
  padding: 20px 0px;
  border: ${props => (props.debug == "true" ? 2 : 0)}px solid green;
  display: flex;
`

const ColLeft = styled.div`
  // flex: 1;
  border: ${props => (props.debug == "true" ? 2 : 0)}px solid blue;
  color: white !important;

  > a {
    color: white !important;
  }
`

const ColCenter = styled.div`
  flex: 1;
  border: ${props => (props.debug == "true" ? 2 : 0)}px solid green;
`

const ColRight = styled.div`
  // flex: 1;
  border: ${props => (props.debug == "true" ? 2 : 0)}px solid brown;
  text-align: right;
`

export default injectIntl(CFooter)
