import {createGlobalStyle} from "styled-components"
import {CONFIG} from "../config"

const DEBUG_STYLE = false && __DEV__ // eslint-disable-line

const GlobalStyle = createGlobalStyle`
  html,
  body {
    margin: 0;
    padding: 0;
    background-color: ${CONFIG.layout.colors.bodyBg};
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ::selection {
    // background-color: rgba(255,255,255,0.5);
    background-color: #f7bc2588;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  h1, h2, h3 {
    margin: 0;
    padding: 0;
    border-bottom: 1px solid #DDD;
    padding-bottom: 10px;
    padding-top: 40px;
    max-width: auto !important;
  }

  h1 {
    margin-bottom: 10px;
    ${DEBUG_STYLE && `background-color: rgba(255,0,0,0.2);`}
  }
  h2 {
    padding-top: 20px;
    margin-bottom: 10px;
    ${DEBUG_STYLE && `background-color: rgba(0,255,0,0.2);`}
  }
  h3 {
    ${DEBUG_STYLE && `background-color: rgba(0,0,255,0.2);`}
  }

  p {
    ${DEBUG_STYLE && `background-color: rgba(255,255,0,0.4);`}
  }

`
export default GlobalStyle
