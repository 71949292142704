import React, {Component} from "react"
import PropTypes from "prop-types"
import isEqual from "lodash/isEqual"
import {createSelector} from "reselect" // eslint-disable-line
import styled, {css, ThemeProvider} from "styled-components" // eslint-disable-line
import Fade from "react-reveal/Fade" // eslint-disable-line

import CProjectItem from "./CProjectItem"

import * as selectors from "../../data/selectors"

const DEBUG = false && __DEV__
import {debbify, getObjectDeep as god} from "../../data/selectors/helpers" // eslint-disable-line
const debby = (...args) => debbify("CFilms", ...args)

class CFilms extends Component {
  static propTypes = {
    items: PropTypes.array,
    tabVisible: PropTypes.bool,
    onItemClick: PropTypes.func,
    mediaQueryClass: PropTypes.oneOf(["xl", "lg", "md", "sm", "xs"]).isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      videoHoveredSlug: null,
    }
    this.videoRefsBySlug = {}
  }

  componentDidMount = () => {
    window.addEventListener("blur", this.onWindowBlur)
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  componentWillUnmount = () => {
    window.removeEventListener("blur", this.onWindowBlur)
  }

  onWindowBlur = () => {
    debby("onWindowBlur()")
    this.onProjectHoverLeave({slug: "--window"})
  }

  renderProject = project => {
    const {videoHoveredSlug} = this.state // eslint-disable-line
    const {mediaQueryClass, tabVisible} = this.props
    // debby("renderProject()", {slug: project.slug, tabVisible, video: project.video})
    return (
      <CProjectItem
        //
        key={`project_${project.id}`}
        ref={obj => (this.videoRefsBySlug[project.slug] = obj)}
        enablePlayback={tabVisible} // not set when losing window focus
        totalIndex={project.totalIndex}
        id={project.id}
        slug={project.slug}
        videoFileKey={project.video.fileKey}
        video={project.video}
        overlay={project.overlay}
        onClick={!!project.video.vimeoUrl ? this.onProjectItemClick : null}
        mediaQueryClass={mediaQueryClass}
        useFullHeight // diff to old version, instead of aspectRatio
        onHoverEnter={this.onProjectHoverEnter}
        onHoverLeave={this.onProjectHoverLeave}
      />
    )
  }

  onProjectItemClick = ({slug}) => {
    debby("onProjectItemClick()", {slug})
    const {onItemClick} = this.props
    !!onItemClick && onItemClick({slug})
  }

  onProjectHoverEnter = ({slug}) => {
    debby("onProjectHoverEnter()", {slug})
    this.setState({videoHoveredSlug: slug})
  }
  onProjectHoverLeave = ({slug}) => {
    debby("onProjectHoverLeave()", {slug})
    for (const slugToPause of Object.keys(this.videoRefsBySlug)) {
      const ref = this.videoRefsBySlug[slugToPause]
      !!ref && ref.pause()
    }
    this.setState({videoHoveredSlug: null})
  }

  render = () => {
    const {videoHoveredSlug} = this.state
    const {items, mediaQueryClass} = this.props
    if (!items || !items.length) {
      return <div />
    }
    const d = {debug: DEBUG.toString()}
    const renderItem = index => this.renderProject(items[index])

    const amountRows = mediaQueryClass != "xs" ? 2 : 1
    const rows = selectors.getArrayChunks(items, amountRows)

    debby("render()", {items, mediaQueryClass, videoHoveredSlug})
    return (
      <ThemeProvider theme={{}}>
        <Container {...d}>
          <ProjectsContainer {...d}>
            {rows.map((items, i) => {
              return (
                <ItemsRow {...d} key={`project_row_${i}`}>
                  {items.map((item, j) => {
                    const itemIsLeft = j == 0
                    const index = i * amountRows + j
                    return (
                      <ProjectItem
                        //
                        {...d}
                        key={`project_${j}`}
                        rowIsEven={JSON.stringify(i % amountRows == 0)}
                        itemIsLeft={JSON.stringify(itemIsLeft)}
                      >
                        <ProjectItemContent {...d} duration={300}>
                          {true && renderItem(index)}
                        </ProjectItemContent>
                        <Fade delay={index * 1000}></Fade>
                      </ProjectItem>
                    )
                  })}
                </ItemsRow>
              )
            })}
          </ProjectsContainer>
        </Container>
      </ThemeProvider>
    )
  }
}

const Container = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
  border: ${props => (props.debug == "true" ? 1 : 0)}px solid red;
  background-color: #000;
  color: white;
`

const ProjectsContainer = styled.div`
  padding: 70px 60px 120px 60px;

  ${props => css`
    ${props.theme.media.md} {
      padding: 70px 20px 100px 20px;
    }
  `}
  ${props => css`
    ${props.theme.media.smdown} {
      padding: 70px 20px 80px 20px;
    }
  `}
`

const ItemsRow = styled.div`
  display: flex;
  flex-direction: row;
  border: ${props => (props.debug == "true" ? 1 : 0)}px solid yellow;
`

const ProjectItem = styled.div`
  position: relative;
  flex: ${props => ((props.rowIsEven == "true" && props.itemIsLeft == "true") || (props.rowIsEven == "false" && props.itemIsLeft == "false") ? 2 : 1)};

  ${props => css`
    ${props.theme.media.smdown} {
      flex: 1;
    }
  `}

  border: ${props => (props.debug == "true" ? 1 : 0)}px solid green;
  position: relative;
  padding: 10px;
  ${props => css`
    ${props.theme.media.smdown} {
      padding: 5px;
    }
  `}

  height: 25vw;
  ${props => css`
    ${props.theme.media.xs} {
      height: 35vw;
    }
  `}
`

const ProjectItemContent = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

export default CFilms
