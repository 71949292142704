import React, {Component} from "react"
import PropTypes from "prop-types"
import isEqual from "lodash/isEqual"
import {createSelector} from "reselect" // eslint-disable-line
import styled, {css, ThemeProvider} from "styled-components" // eslint-disable-line
import SmoothCollapse from "react-smooth-collapse" // eslint-disable-line

import {Container as GridContainer, Row, Col} from "react-grid-system"

import MemberShapeLeftSvg from "../../images/svg/MemberShapeLeft.svg"
import MemberShapeRightSvg from "../../images/svg/MemberShapeRight.svg"

import * as selectors from "../../data/selectors"

import {HiddenH2} from "../../view/components/Styled"
import CSocialIcons from "../../view/components/CSocialIcons"

const DEBUG = false && __DEV__
import {debbify, getObjectDeep as god} from "../../data/selectors/helpers" // eslint-disable-line
const debby = (...args) => debbify("CTeamMembers", ...args)

const USE_SINGLE_ROW_ON_XS = true
const USE_CLICK_INSTEAD_OF_HOVER = true

class CTeamMembers extends Component {
  static propTypes = {
    items: PropTypes.array.isRequired,
    contactHeader: PropTypes.string,
    contactAddress1: PropTypes.string,
    contactAddress2: PropTypes.string,
    contactInstagramHandle: PropTypes.string,
    contactInstagram: PropTypes.string,
    urlFacebook: PropTypes.string,
    urlInstagram: PropTypes.string,
    contactTwitterHandle: PropTypes.string,
    urlTwitter: PropTypes.string,
    urlVimeo: PropTypes.string,
    // mediaQueryClass: PropTypes.oneOf(["xl", "lg", "md", "sm", "xs"]).isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      selectedItemIndex: !DEBUG ? -1 : 1,
    }
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  openInstagram = () => {
    const {contactInstagram} = this.props
    if (!contactInstagram) {
      return
    }
    window.open(contactInstagram)
  }

  openTwitter = () => {
    const {urlTwitter} = this.props
    if (!urlTwitter) {
      return
    }
    window.open(urlTwitter)
  }

  onMemberTitleRoleClick = index => {
    debby("onMemberTitleRoleClick()", {index})
    this.setState({selectedItemIndex: index})
  }

  onMemberFactClick = index => {
    debby("onMemberFactClick()", {index})
    this.setState({selectedItemIndex: -1})
  }

  onMemberEmailClick = index => {
    debby("onMemberEmailClick()", {index})
    const {email} = this.props.items[index]
    window.open(`mailto:${email}`)
  }

  render = () => {
    const {selectedItemIndex} = this.state
    const {items, contactHeader, contactAddress1, contactAddress2, contactInstagramHandle, contactTwitterHandle} = this.props
    const {urlFacebook, urlInstagram, urlTwitter, urlVimeo} = this.props
    if (!items || !items.length) {
      return <div />
    }
    const d = {debug: DEBUG.toString()}
    debby("render()", {items, selectedItemIndex})

    const rowPropsContact = {
      type: "flex",
      align: "start",
      // justify: "end",
      justify: "start",
      width: "100%",
      height: "100%",
    }

    const colProps = {xl: 4, lg: 4, md: 6, sm: 6, xs: USE_SINGLE_ROW_ON_XS ? 12 : 6}
    return (
      <ThemeProvider theme={{}}>
        <Container {...d} paddingVariant={"teammembers"}>
          <HiddenH2>Team Members</HiddenH2>
          <StyledGridContainer {...d}>
            {true && (
              <Row>
                {items.map((item, i) => {
                  const {title, role, email, weirdFact} = item
                  const weirdFactLength = weirdFact.length
                  const weirdFactTextScale = weirdFactLength > 200 ? 0.7 : weirdFactLength > 150 ? 0.8 : 1
                  return (
                    <StyledGridCol key={`member_${i}`} {...colProps}>
                      <MemberContainer
                        //
                        {...d}
                        {...(!USE_CLICK_INSTEAD_OF_HOVER ? {onClick: () => this.onMemberEmailClick(i)} : {})}
                        shownViaClick={JSON.stringify(selectedItemIndex == i)}
                      >
                        {/* Title ///////////////////////////////////////////////////////////////// */}
                        <MemberTitle
                          //
                          {...d}
                          {...(USE_CLICK_INSTEAD_OF_HOVER ? {onClick: () => this.onMemberTitleRoleClick(i)} : {})}
                        >
                          {title}
                          <span style={{fontSize: 12}}>{DEBUG ? ` (${weirdFactLength}/${weirdFactTextScale.toFixed(1)})` : ``}</span>
                        </MemberTitle>
                        {/* Role ////////////////////////////////////////////////////////////////// */}
                        <MemberRole
                          //
                          {...d}
                          dangerouslySetInnerHTML={{__html: role}}
                          {...(USE_CLICK_INSTEAD_OF_HOVER ? {onClick: () => this.onMemberTitleRoleClick(i)} : {})}
                        />
                        {/* Email ///////////////////////////////////////////////////////////////// */}
                        <MemberEmailV2
                          //
                          {...d}
                          dangerouslySetInnerHTML={{__html: email}}
                          {...(USE_CLICK_INSTEAD_OF_HOVER ? {onClick: () => this.onMemberEmailClick(i)} : {})}
                        />
                        {/* Weird Fact //////////////////////////////////////////////////////////// */}
                        <MemberOverlayContainer
                          //
                          {...d}
                          id={`member-overlay`}
                          {...(USE_CLICK_INSTEAD_OF_HOVER ? {onClick: () => this.onMemberFactClick(i)} : {})}
                          // shownViaClick={JSON.stringify(selectedItemIndex == i)}
                        >
                          <MemberOverlayContent {...d}>
                            <MemberShapeLeft {...d}>
                              <MemberShapeImage src={MemberShapeLeftSvg} />
                            </MemberShapeLeft>
                            <MemberWeirdFactV2 {...d} textScale={`${weirdFactTextScale}`} dangerouslySetInnerHTML={{__html: weirdFact}} />
                            <MemberShapeRight {...d}>
                              <MemberShapeImage src={MemberShapeRightSvg} />
                            </MemberShapeRight>
                          </MemberOverlayContent>
                        </MemberOverlayContainer>
                      </MemberContainer>
                    </StyledGridCol>
                  )
                })}
              </Row>
            )}
            <Row style={{marginTop: 30, marginBottom: 30}} {...rowPropsContact}>
              <StyledGridCol {...colProps}>
                <MemberContainer {...d} clickable={JSON.stringify(false)} className={"notclickable"}>
                  <MemberTitle {...d} clickable={JSON.stringify(false)} lowerCase={JSON.stringify(false)}>
                    {contactHeader}
                  </MemberTitle>
                  <MemberRole dangerouslySetInnerHTML={{__html: contactAddress1}} clickable={JSON.stringify(false)} />
                  <MemberRole dangerouslySetInnerHTML={{__html: contactAddress2}} clickable={JSON.stringify(false)} />
                </MemberContainer>
              </StyledGridCol>
              {false && (
                <StyledGridCol {...colProps}>
                  <MemberContainer {...d} clickable={JSON.stringify(true)} onClick={this.openInstagram}>
                    <MemberTitle {...d} clickable={JSON.stringify(false)}>
                      Instagram
                    </MemberTitle>
                    <MemberRole dangerouslySetInnerHTML={{__html: contactInstagramHandle}} clickable={JSON.stringify(false)} />
                  </MemberContainer>
                </StyledGridCol>
              )}
              {false && (
                <StyledGridCol {...colProps}>
                  <MemberContainer {...d} clickable={JSON.stringify(true)} onClick={this.openTwitter}>
                    <MemberTitle {...d} clickable={JSON.stringify(false)}>
                      Twitter
                    </MemberTitle>
                    <MemberRole dangerouslySetInnerHTML={{__html: contactTwitterHandle}} clickable={JSON.stringify(false)} />
                  </MemberContainer>
                </StyledGridCol>
              )}
            </Row>

            <SocialIconsContainer {...d}>
              <CSocialIcons
                //
                urlFacebook={urlFacebook}
                urlInstagram={urlInstagram}
                urlTwitter={urlTwitter}
                urlVimeo={urlVimeo}
                horizontal
              />
            </SocialIconsContainer>
          </StyledGridContainer>
        </Container>
      </ThemeProvider>
    )
  }
}

const Container = styled.div`
  position: relative;
  overflow: hidden;
  border: ${props => (props.debug == "true" ? 2 : 0)}px solid red;
  // letter-spacing: 0.03em;
  ${props => selectors.getPaddingLeftRightByThemeAndVariant(props.theme, props.paddingVariant)}
  margin: 0 auto;
  color: white;
  background-color: black;

  padding-top: 100px;
  padding-bottom: 100px;

  ${props => css`
    ${props.theme.media.mddown} {
      padding-top: 50px;
      padding-bottom: 50px;
    }
  `}
`

const StyledGridContainer = styled(GridContainer)`
  border: ${props => (props.debug == "true" ? 1 : 0)}px solid red;
`

const StyledGridCol = styled(Col)`
  border: ${props => (props.debug == "true" ? 1 : 0)}px solid green;
`

const MemberFontSizeXl = 21
const MemberContainer = styled.div`
  border: ${props => (props.debug == "true" ? 1 : 0)}px solid yellow;

  // Responsiveness
  font-size: ${MemberFontSizeXl}px;
  padding: 40px;
  ${props => css`
    ${props.theme.media.xs} {
      font-size: ${MemberFontSizeXl * 0.8}px;
      padding: 30px 30px;
    }
  `}
  ${props => css`
    ${props.theme.media.sm} {
      font-size: ${MemberFontSizeXl * 0.7}px;
      padding: 30px;
    }
  `}
  ${props => css`
    ${props.theme.media.md} {
      font-size: ${MemberFontSizeXl * 0.8}px;
      padding: 30px;
    }
  `}
  ${props => css`
    ${props.theme.media.lg} {
      font-size: ${MemberFontSizeXl * 0.8}px;
      padding: 30px;
    }
  `}

  // Hover / Click
  ${!USE_CLICK_INSTEAD_OF_HOVER &&
  css`
    cursor: pointer;
    div#member-overlay {
      transition: all 0.2s ease-out;
      opacity: ${props => (props.debug == "true" ? 0.3 : 0)};
      transform: scale(0.9);
    }
    :hover {
      div#member-overlay {
        opacity: 1;
        transform: scale(1);
      }
    }
  `}

  ${USE_CLICK_INSTEAD_OF_HOVER &&
  css`
    // cursor: ${props => (props.clickable != "false" ? "pointer" : "default")};

    ${props =>
      props.clickable != "false" &&
      css`
        // cursor: pointer;
      `}

    div#member-overlay {
      transition: all 0.2s ease-out;
      opacity: ${props => (props.shownViaClick == "true" ? 1 : props.debug == "true" ? 0.3 : 0)};
      transform: scale(${props => (props.shownViaClick == "true" ? 1 : 0.9)});
      pointer-events: ${props => (props.shownViaClick == "true" ? "all" : "none")};
      ${props =>
        props.shownViaClick == "true" &&
        props.clickable != "false" &&
        css`
          cursor: pointer;
        `};
    }
  `}
`

const MemberTitle = styled.div`
  color: white;
  font-weight: 900;
  text-transform: ${props => (props.lowerCase != "false" ? "uppercase" : "none")};
  border: ${props => (props.debug == "true" ? 1 : 0)}px solid white;
  ${props =>
    props.clickable != "false" &&
    USE_CLICK_INSTEAD_OF_HOVER &&
    css`
      cursor: pointer;
    `}
`

const MemberRole = styled.div`
  color: white;
  font-weight: normal;
  border: ${props => (props.debug == "true" ? 1 : 0)}px solid green;
  ${props =>
    props.clickable != "false" &&
    USE_CLICK_INSTEAD_OF_HOVER &&
    css`
      cursor: pointer;
    `}
`

const MemberEmailV2 = styled.div`
  display: inline-block;
  color: white;
  font-weight: normal;
  text-decoration: underline;
  border: ${props => (props.debug == "true" ? 1 : 0)}px solid blue;
  ${USE_CLICK_INSTEAD_OF_HOVER &&
  css`
    cursor: pointer;
  `}
`

const MemberOverlayContainer = styled.div`
  position: absolute;
  pointer-events: none;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #f7bc25;
  overflow: visible;
`

const MemberOverlayContent = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
`

const MemberShapeLeft = styled.div`
  width: 20px;
  background-color: black;
`

const MemberShapeRight = styled.div`
  width: 20px;
  background-color: #f7bc25;
`

const MemberShapeImage = styled.img`
  height: 100%;
  width: 100%;
`

const MemberWeirdFactV2 = styled.div`
  color: black;
  flex: 1;
  align-self: stretch;
  border: ${props => (props.debug == "true" ? 1 : 0)}px solid blue;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: ${props => parseFloat(props.textScale || 1) * 18}px;
  padding: 10px;
  ${props => css`
    ${props.theme.media.xs} {
      font-size: ${props => parseFloat(props.textScale || 1) * 18 * 1}px;
      padding: 10px;
    }
  `}
  ${props => css`
    ${props.theme.media.sm} {
      font-size: ${MemberFontSizeXl * 0.5}px;
    }
  `}
  ${props => css`
    ${props.theme.media.md} {
      font-size: ${MemberFontSizeXl * 0.55}px;
    }
  `}
  ${props => css`
    ${props.theme.media.lg} {
      font-size: ${MemberFontSizeXl * 0.55}px;
    }
  `}
`

const SocialIconsContainer = styled.div`
  display: none;
  ${props => css`
    ${props.theme.media.smdown} {
      display: block;
    }
  `}
`

export default CTeamMembers
