import React, {Component} from "react"
import PropTypes from "prop-types"
import {connect} from "react-redux"
import isEqual from "lodash/isEqual"
import {createSelector} from "reselect" // eslint-disable-line
import styled, {css, ThemeProvider} from "styled-components" // eslint-disable-line
// import {Link} from "react-router-dom"

import LogoNew from "../../images/png/ZoomSportLogoNew.png"

import * as actions from "../../data/actions"
import * as selectors from "../../data/selectors"

const DEBUG = false && __DEV__
import {debbify, getObjectDeep as god} from "../../data/selectors/helpers" // eslint-disable-line
const debby = (...args) => debbify("CMenu", ...args)

class CMenu extends Component {
  static propTypes = {
    screen: PropTypes.oneOf(["main", "cookie-policy"]).isRequired,
    height: PropTypes.number,
    heightSm: PropTypes.number,
    // connect
    dispatch: PropTypes.func.isRequired,
    elementsY: PropTypes.object,
    videoLightboxShown: PropTypes.bool,
  }
  static defaultProps = {
    color: "white",
    height: 80,
    heightSm: 60,
  }

  constructor(props) {
    super(props)
    this.state = {
      activeMenuItemKey: "",
    }
  }

  componentDidMount = () => {
    this.intervalUpdateActiveMenuItemKey = setInterval(this.updateActiveMenuItemKey, 300)
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  componentWillUnmount = () => {
    clearInterval(this.intervalUpdateActiveMenuItemKey)
  }

  updateActiveMenuItemKey = () => {
    const {elementsY} = this.props
    const scrollY = selectors.getWindowScrollY() + 10
    // debby("updateActiveMenuItemKey()", {scrollY})

    let activeMenuItemKey = ""
    if (!!elementsY && Object.keys(elementsY).length) {
      const heroY = god(elementsY, "hero", 10000)
      const filmsY = god(elementsY, "films", 10000)
      const aboutY = god(elementsY, "about", 10000)
      const teamY = god(elementsY, "team", 10000)

      if (scrollY > heroY) {
        activeMenuItemKey = "hero"
      }
      if (scrollY > filmsY) {
        activeMenuItemKey = "films"
      }
      if (scrollY > aboutY) {
        activeMenuItemKey = "about"
      }
      if (scrollY > teamY) {
        activeMenuItemKey = "team"
      }
    }

    this.setState({activeMenuItemKey})
  }

  scrollToElementY = key => {
    const {elementsY} = this.props
    const elementY = god(elementsY, key, -1)
    debby("scrollToElementY()", {key, elementY})
    if (elementY == -1) {
      return
    }
    this.props.dispatch(actions.scrollToY(elementY))
  }

  render = () => {
    const {activeMenuItemKey} = this.state
    const {screen, videoLightboxShown} = this.props
    const {elementsY} = this.props
    const d = {debug: DEBUG.toString()}
    const scrollY = selectors.getWindowScrollY()
    debby("render()", {videoLightboxShown, screen, scrollY, elementsY, activeMenuItemKey})
    const activeItemKey = videoLightboxShown ? "films" : activeMenuItemKey
    return (
      <ThemeProvider theme={{}}>
        <Container {...d}>
          <PaddedContainer {...d} paddingVariant={"header"}>
            <MainContainer {...d}>
              <MenuItemsContainer {...d}>
                <MenuItem
                  //
                  {...d}
                  active={JSON.stringify(activeItemKey == "films")}
                  allowClicks={JSON.stringify(!videoLightboxShown)}
                  onClick={() => this.scrollToElementY("films")}
                  fadeOut={JSON.stringify(false)}
                >
                  Films
                </MenuItem>
                <MenuItem
                  //
                  {...d}
                  allowClicks={JSON.stringify(!videoLightboxShown)}
                  active={JSON.stringify(activeItemKey == "about")}
                  onClick={() => this.scrollToElementY("about")}
                  fadeOut={JSON.stringify(videoLightboxShown)}
                >
                  About
                </MenuItem>
                <MenuItem
                  //
                  {...d}
                  allowClicks={JSON.stringify(!videoLightboxShown)}
                  active={JSON.stringify(activeItemKey == "team")}
                  onClick={() => this.scrollToElementY("team")}
                  fadeOut={JSON.stringify(videoLightboxShown)}
                >
                  The Team
                </MenuItem>
              </MenuItemsContainer>
              <LogoContainer {...d}>
                <LogoImage
                  //
                  allowClicks={JSON.stringify(!videoLightboxShown)}
                  src={LogoNew}
                  onClick={() => this.props.dispatch(actions.scrollToY(0))}
                />
              </LogoContainer>
            </MainContainer>
          </PaddedContainer>
        </Container>
      </ThemeProvider>
    )
  }
}

const Container = styled.div`
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  right: 0;
  max-width: ${props => props.theme.vars.maxWidth.container}px;
  margin: 0 auto;
  ${props =>
    props.debug == "true" &&
    css`
      background-color: rgba(0, 255, 255, 0.5);
    `}
  pointer-events: none;

  display: flex;
  align-items: center;

  height: ${CMenu.defaultProps.height}px;
  transition: height 0.5s;
  ${props => css`
    ${props.theme.media.smdown} {
      height: ${CMenu.defaultProps.heightSm}px;
    }
  `}
`

const PaddedContainer = styled.div`
  position: relative;
  width: 100%;
  margin: 0;
  padding: 0;
  ${props => false && selectors.getPaddingLeftRightByThemeAndVariant(props.theme, props.paddingVariant)}
  border: ${props => (props.debug == "true" ? 1 : 0)}px solid yellow;

  ${props => css`
    ${props.theme.media.smdown} {
      // margin-right: 20px;
    }
  `}
`

const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`

const MenuItemsContainer = styled.div`
  flex: 1;
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  padding: 0px 30px;
  ${props => css`
    ${props.theme.media.smdown} {
      display: none;
    }
  `}
`

const MenuItem = styled.div`
  border: ${props => (props.debug == "true" ? 1 : 0)}px solid green;
  text-transform: uppercase;
  cursor: pointer;
  pointer-events: ${props => (props.allowClicks == "true" ? "all" : "none")};

  transition: color 0.5s, opacity 1.5s;
  color: ${props => (props.active == "true" ? props.theme.vars.colors.fixedProjectBg : "white")};
  opacity: ${props => (props.fadeOut == "false" ? 1 : 0.2)};

  text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3);
  font-family: "Strawford";
  font-weight: 900;

  font-size: 19px;
  padding: 20px 40px;
  ${props => css`
    ${props.theme.media.md} {
      font-size: 17px;
      padding: 10px 20px;
    }
  `}
`

const LogoContainer = styled.div`
  display: flex;
  border: ${props => (props.debug == "true" ? 1 : 0)}px solid red;

  margin-right: ${40 + 10}px;
  ${props => css`
    ${props.theme.media.smdown} {
      margin-right: 0;
    }
  `}

  pointer-events: ${props => (props.allowClicks == "true" ? "all" : "none")};
`

const LogoImage = styled.img`
  object-fit: contain;
  padding: 20px;
  cursor: pointer;
  filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.3));
  pointer-events: ${props => (props.allowClicks == "true" ? "all" : "none")};

  width: 200px;
  ${props => css`
    ${props.theme.media.smdown} {
      width: 150px;
    }
  `}
`

const getScreen = (_, props) => props.screen
const getElementsYByKey = state => state.layout.elementsY

const getElementsY = createSelector([getElementsYByKey, getScreen], (elementsYByKey, screen) => {
  return god(elementsYByKey, screen, {})
})

const mapStateToProps = (state, props) => ({
  elementsY: getElementsY(state, props),
  videoLightboxShown: state.routing.videoLightboxShown,
})
export default connect(mapStateToProps)(CMenu)
