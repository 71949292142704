import * as actionsConsts from "../actionsConsts"

export const initialState = {
  videoLightboxShown: false,
  videoLightboxProps: null,
}

export function routing(state = initialState, action) {
  switch (action.type) {
    case actionsConsts.SHOW_VIDEO_LIGHTBOX: {
      return {...state, videoLightboxShown: true, videoLightboxProps: action.props}
    }
    case actionsConsts.HIDE_VIDEO_LIGHTBOX: {
      return {...state, videoLightboxShown: false}
    }
    default:
      return state
  }
}
