import queryString from "query-string"
import {createSelector} from "reselect"
import {getObjectDeep as god} from "./helpers"
import {getIntlLocale} from "./intl" // eslint-disable-line

export const getLocationPathname = (state, props) => props.location.pathname
export const getPropsLocation = (state, props) => props.location
export const getQueryProps = (state, props) => queryString.parse(props.location.search)

const getLocation = (state, props) => props.location

const getStateApiPostData = state => state.api.postData

export const getPostData = createSelector(
  //
  [getLocation, getStateApiPostData],
  (location, stateApiPostData) => {
    const {pathname} = location
    const postData = god(stateApiPostData, [pathname, "data"])
    return postData
  },
)
