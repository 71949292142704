import React, {Component} from "react"
import PropTypes from "prop-types"
import isEqual from "lodash/isEqual"
import styled, {css, ThemeProvider} from "styled-components" // eslint-disable-line

// import BugReportManager from "../../data/utils/BugReportManager"
// import {PaddedContentMaxWidth} from "../../view/styled"
import {CONFIG} from "../../config"

// import CProjectItem from "./CProjectItem"

import * as selectors from "../../data/selectors"

const DEBUG = false && __DEV__
import {debbify} from "../../data/selectors/helpers"
const debby = (...args) => debbify("CWpEditButton", ...args)

class CWpEditButton extends Component {
  static propTypes = {
    postId: PropTypes.number,
    customPath: PropTypes.string,
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  onEditClick = () => {
    const {customPath} = this.props
    let urlUsed = null
    if (!!customPath) {
      // urlUsed = `https://zoomsport.com/${customPath}`
      urlUsed = `${CONFIG.server.root}/${customPath}`
    } else {
      urlUsed = selectors.getWpEditLink(this.props.postId)
    }
    window.open(urlUsed)
  }

  render = () => {
    const {postId} = this.props
    const d = {debug: DEBUG.toString()}
    const wpEditLink = selectors.getWpEditLink(postId)
    debby("render()", {wpEditLink})
    return (
      <ThemeProvider theme={{}}>
        <Container {...d}>
          <EditLink {...d} onClick={this.onEditClick}>
            <EditChar {...d}>✎</EditChar>
          </EditLink>
        </Container>
      </ThemeProvider>
    )
  }
}

const Container = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
  border: ${props => (props.debug == "true" ? 2 : 0)}px solid red;
  z-index: 10000;
`

const EditLink = styled.div`
  // text-decoration: underline;
  font-size: 25px;
  font-weight 900;
  text-transform: uppercase;
  color: white;
  line-height: 1;
  background-color: rgba(0, 0, 0, 0.75);
  border: 1px solid white;
  border-radius: 50px;
  // padding: 10px 10px;
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const EditChar = styled.div`
  border: ${props => (props.debug == "true" ? 1 : 0)}px solid yellow;
  transform: translate3d(0px, 3px, 0px);
`

export default CWpEditButton
