import startCase from "lodash/startCase"
import objectPath from "object-path"
import isObject from "lodash/isObject"

import {CONFIG} from "../../config" // eslint-disable-line

const MUTE_DEBBY = false

const getConsoleFunc = (warn = false) => (!warn ? console.log : console.warn)
export const debbify = (prefix, funcName, data = {}, isWarning = false) => !MUTE_DEBBY && process.env.NODE_ENV != "test" && (Object.keys(data).length ? getConsoleFunc(isWarning)(`${prefix}.` + funcName, data) : getConsoleFunc(isWarning)(`${prefix}.` + funcName))

export const getObjectSorted = obj =>
  Object.keys(obj)
    .sort()
    .reduce((result, key) => {
      const value = obj[key]
      if (isObject(value)) {
        result[key] = getObjectSorted(value)
      } else {
        result[key] = obj[key]
      }
      return result
    }, {})

export const getAllUrlParams = url => {
  var queryString = url ? url.split("?")[1] : window.location.search.slice(1)

  var obj = {}

  if (queryString) {
    queryString = queryString.split("#")[0]

    var arr = queryString.split("&")

    for (var i = 0; i < arr.length; i++) {
      var a = arr[i].split("=")

      var paramName = a[0]
      var paramValue = typeof a[1] === "undefined" ? true : a[1]

      paramName = paramName.toLowerCase()
      if (typeof paramValue === "string") {
        paramValue = paramValue.toLowerCase()
      }

      // if (paramName.match(/\[(\d+)?\]$/)) {
      //   var key = paramName.replace(/\[(\d+)?\]/, "")
      //   if (!obj[key]) obj[key] = []

      //   if (paramName.match(/\[\d+\]$/)) {
      //     var index = /\[(\d+)\]/.exec(paramName)[1]
      //     obj[key][index] = paramValue
      //   } else {
      //     obj[key].push(paramValue)
      //   }
      // }
      // else {
      if (!obj[paramName]) {
        obj[paramName] = paramValue
      }
      // else if (obj[paramName] && typeof obj[paramName] === "string") {
      //   obj[paramName] = [obj[paramName]]
      //   obj[paramName].push(paramValue)
      // }
      // else {
      //   obj[paramName].push(paramValue)
      // }
      // }
    }
  }

  return obj
}

export const getUrlReadable = url => {
  if (!url) {
    return ""
  }
  let urlReadable = `${url}`
  urlReadable = urlReadable.split("https://").join("")
  urlReadable = urlReadable.split("http://").join("")
  return urlReadable
}

export const getArrayOrNull = array => (!!array && !!array.length ? array : null)

export const getArrayShuffledWithSeed = (array, seed) => {
  if (!array) {
    return null
  }
  let currentIndex = array.length,
    temporaryValue,
    randomIndex
  seed = seed || 1
  let random = function () {
    var x = Math.sin(seed++) * 10000
    return x - Math.floor(x)
  }
  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(random() * currentIndex)
    currentIndex -= 1
    // And swap it with the current element.
    temporaryValue = array[currentIndex]
    array[currentIndex] = array[randomIndex]
    array[randomIndex] = temporaryValue
  }
  return array
}

export const getDeviceLocale = () => getBrowserLang()
export const getLocaleUsable = locale => CONFIG.intl.langs.indexOf(locale) != -1
export const getLocaleSafe = locale => (getLocaleUsable(locale) ? locale : CONFIG.intl.baseLang)
export const getDeviceLocaleUsable = () => getLocaleSafe(getDeviceLocale())

export const getBrowserLang = () => {
  let localeLang = "en" // default
  if (!!navigator && !!navigator.language) {
    var locale = navigator.language.split("-")
    localeLang = locale[0].toLowerCase()
  }
  return localeLang
}

export const getCC = input => {
  const inputStart = startCase(input)
  const inputStartWithLink = `${inputStart}`
  const result = inputStartWithLink.split(" ").join("")
  return result
}

// Object Manipulation

export const getObjectDeep = (obj, pathRaw, fallback = undefined, fallbackCompare = undefined) => {
  if (!!pathRaw && Array.isArray(pathRaw)) {
    let value = objectPath.get(obj, pathRaw)
    if (value == fallbackCompare) {
      return fallback
    }
    return value
  }

  let path = pathRaw.split("/").join(".")
  let value = objectPath.get(obj, path)
  if (value == fallbackCompare) {
    return fallback
  }
  return value
}

export const setObjectDeep = (obj, pathRaw, value, levelSeparator = "/") => {
  let path = pathRaw
  path = pathRaw.split(levelSeparator).join(".") //.split (".")

  var objNew = Object.assign({}, obj)
  if (value != null) {
    objectPath.set(objNew, path, value)
  } else {
    objectPath.del(objNew, path)
  }
  return objNew
}

// Routing

export const getHrefRecursively = target => {
  if (target.nodeName == "A") {
    return target.href
  } else if (!!target.parentNode) {
    return getHrefRecursively(target.parentNode)
  }
  return null
}

export const getHrefByClickEvent = event => (!event ? null : getHrefRecursively(event.target))

export const getArrayChunks = (array, chunk_size) =>
  Array(Math.ceil(array.length / chunk_size))
    .fill()
    .map((_, index) => index * chunk_size)
    .map(begin => array.slice(begin, begin + chunk_size))

export const getWpEditLink = postId => `https://zoomsport.com/wp/wp-admin/post.php?post=${postId}&action=edit`

export const wait = (ms = 100) => new Promise(resolve => setTimeout(resolve, ms))
