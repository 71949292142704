import {waitUntilAnalyticsReady} from "../../data/actions/AFirebase"
import {debbify} from "../../data/selectors/helpers"
import {getWindowLocationPathnameCleanForAnalytics} from "../../data/selectors/helpers-pure"
import * as actionsConsts from "../../data/actionsConsts"

import {CONFIG} from "../../config"

const debby = (...args) => debbify("AAnalytics", ...args)

export const setAnalyticsReady = ({enabled, reason}) => dispatch => {
  debby("setAnalyticsReady()", {enabled, reason})
  dispatch({type: actionsConsts.SET_ANALYTICS_READY_ENABLED, enabled, reason})
}

export const sendAnalyticsPageView = () => dispatch => {
  const windowLocationPathname = window.location.pathname
  const path = getWindowLocationPathnameCleanForAnalytics(windowLocationPathname, CONFIG.server.folderReact)
  // debby("sendAnalyticsPageView()", {windowLocationPathname, path})
  if (path == lastPath) {
    return
  }
  lastPath = path

  clearTimeout(sendAnalyticsPageViewTimeoutId)
  sendAnalyticsPageViewTimeoutId = setTimeout(() => {
    dispatch(waitUntilAnalyticsReady("sendAnalyticsPageView()")).then(({firebase, enabled}) => {
      if (enabled) {
        debby("sendAnalyticsPageView()", {path})
        firebase.analytics().setCurrentScreen(path)
      }
    })
  }, 500)
}
let lastPath = undefined
let sendAnalyticsPageViewTimeoutId = undefined

export const setAnalyticsUserId = userId => async dispatch => {
  const {firebase, enabled} = await dispatch(waitUntilAnalyticsReady("setAnalyticsUserId()"))
  if (enabled) {
    debby("setAnalyticsUserId()", {userId})
    firebase.analytics().setUserId(userId)
    dispatch(setAnalyticsUserProperty("uid", userId))
  }
}

export const sendAnalyticsEvent = (eventName, eventParams = undefined) => async dispatch => {
  const {firebase, enabled} = await dispatch(waitUntilAnalyticsReady("sendAnalyticsEvent()"))
  if (enabled) {
    debby("sendAnalyticsEvent()", {eventName, eventParams})
    firebase.analytics().logEvent(eventName, eventParams)
  }
}

let userProperties = {}
export const setAnalyticsUserProperty = (key, value) => async dispatch => {
  const {firebase, enabled} = await dispatch(waitUntilAnalyticsReady("setAnalyticsUserProperty()"))
  if (enabled) {
    userProperties[key] = value
    debby("setAnalyticsUserProperty()", {key, value, userProperties})
    firebase.analytics().setUserProperties(userProperties)
  }
}
