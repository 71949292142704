import React, {Component} from "react"
import isEqual from "lodash/isEqual"

import CHeader from "./CHeader"

const DEBUG = false && __DEV__ // eslint-disable-line
import {debbify, getObjectDeep as god} from "../../data/selectors/helpers" // eslint-disable-line
const debby = (...args) => debbify("CHeader", ...args)

class CHeaderSpacer extends Component {
  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))
  render = () => {
    const {height} = CHeader.defaultProps
    debby("render()", {height})
    return <div style={{height, ...(DEBUG ? {backgroundColor: "red"} : {})}} />
  }
}

export default CHeaderSpacer
