import React, {Component} from "react"
import PropTypes from "prop-types"
import {Route} from "react-router-dom"
import {createBrowserHistory} from "history"
import {Provider} from "react-intl-redux"
import {ConnectedRouter} from "connected-react-router"
import isEqual from "lodash/isEqual"
import {setConfiguration} from "react-grid-system"
import {PersistGate} from "redux-persist/integration/react"

import {CONFIG} from "./config"
import {createAndPersistStore} from "./data/store"
// import AppWithState from "./view/AppWithState_Basic" // Setup only
import AppWithState from "./view/AppWithState"
import * as selectors from "./data/selectors"
import * as actions from "./data/actions"

const {basename} = CONFIG.routing
let usedHistory = createBrowserHistory({basename})

let storeRef = null
let persistorRef = null

const DEBUG = true && __DEV__ // eslint-disable-line

import {debbify} from "./data/selectors/helpers"
const debby = (...args) => debbify("App", ...args)

setConfiguration(selectors.getChildContextGrid()) // initialize react-grid-system

class App extends Component {
  static propTypes = {
    usePersistGate: PropTypes.bool,
  }
  static defaultProps = {
    usePersistGate: true,
  }

  constructor(props) {
    super(props)
    this.state = {
      drawProvider: false,
      uidInit: null,
    }
  }

  componentDidMount = async () => {
    let localeDefault = selectors.getDeviceLocaleUsable()
    localeDefault = "en"
    // localeDefault = "es"

    const urlParams = selectors.getAllUrlParams(window.location.href)

    // let phonePrefix = undefined // see intialState for RDevice
    // if (!!urlParams && Object.keys(urlParams).length && urlParams.hasOwnProperty("pre")) {
    //   phonePrefix = urlParams.pre
    //   debugger
    // }

    let shownInInAppBrowser = false
    if (!!urlParams && Object.keys(urlParams).length && urlParams.hasOwnProperty("ia") && urlParams["ia"] == "1") {
      // Site launched in In-App browser of Telegram -> show note
      shownInInAppBrowser = true
    }

    // will purge in case storePersistKey changed in config.js
    const {store, persistor} = await createAndPersistStore({locale: localeDefault, history: usedHistory, shownInInAppBrowser})
    store.dispatch(actions.initFirebase()) // AFirebase.js

    store.dispatch(actions.setAnalyticsUserProperty("language", localeDefault)) // AAnalytics.js

    debby("componentDidMount()", {urlParams, localeDefault})

    storeRef = store
    persistorRef = persistor
    this.setState({drawProvider: true})
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  renderRoute = ({location}) => <AppWithState location={location} />

  render = () => {
    const {usePersistGate} = this.props
    if (!this.state.drawProvider) {
      return <div style={{height: 10000}} /> // enables scroll restauration on reload
    }
    const renderRouter = () => (
      <ConnectedRouter history={usedHistory}>
        <Route render={this.renderRoute} />
      </ConnectedRouter>
    )

    if (usePersistGate) {
      return (
        <Provider store={storeRef}>
          <PersistGate loading={<PersistLoadingIndicator />} persistor={persistorRef}>
            {renderRouter()}
          </PersistGate>
        </Provider>
      )
    }
    return <Provider store={storeRef}>{renderRouter()}</Provider>
  }
}

class PersistLoadingIndicator extends Component {
  componentWillUnmount = () => {
    // console.log("PersistLoadingIndicator.componentWillUnmount()")
  }
  render = () => {
    // console.log("PersistLoadingIndicator.render()")
    return null
  }
}

export default App
