import styled, {css} from "styled-components"

export const DivMainContent = styled.div`
  letter-spacing: 0.03em;
  > p {
    margin: 0;
    padding: 0;
    padding-bottom: 40px;
  }

  font-size: 30px;
  line-height: 1.3;
  ${props => css`
    ${props.theme.media.smdown} {
      font-size: 14px;
      > p {
        padding-bottom: 12px;
      }
    }
  `}
`

const HiddenHeader = `
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
`

export const HiddenH1 = styled.h1`
  ${HiddenHeader}
`
export const HiddenH2 = styled.h2`
  ${HiddenHeader}
`
