import axios from "axios"
import * as actionsConsts from "../../data/actionsConsts"

import {CONFIG} from "../../config" // eslint-disable-line

// import {setAnalyticsUserProperty} from "./AAnalytics"

import {debbify, getObjectDeep as god} from "../../data/selectors/helpers"
const debby = (...args) => debbify("AAPI", ...args)
import BugReportManager from "../../data/utils/BugReportManager"

export const requestPostData =
  (pathRaw, options = {}) =>
  async (dispatch, getState) => {
    let success = false
    let pageTemplate = null
    let postData = null

    const fromPreload = !!options && options.hasOwnProperty("fromPreload") ? options["fromPreload"] : false

    let path = `${pathRaw}`
    while (path != "/" && path.endsWith("/")) {
      path = path.substr(0, path.length - 1)
    }

    const stateApi = getState().api
    if (!!stateApi) {
      // otherwise test will fail
      const postDataState = stateApi.postData
      if (postDataPathsRequestedInSession.indexOf(path) != -1 && postDataState.hasOwnProperty(path) && !postDataState[path].busy && !!postDataState[path].data) {
        postData = postDataState[path].data
        pageTemplate = postDataState[path].pageTemplate
        return {success: true, pageTemplate, data: postData}
      }
    }

    fromPreload && debby(`requestPostData(): Preloading`, {path})
    !fromPreload && debby(`requestPostData(): Loading`, {path})

    dispatch({type: actionsConsts.REQUEST_POST_DATA, path})

    try {
      const params = {params: {path}}
      const serverResponse = await axios.get(`${CONFIG.server.root}/api/postdata`, params)
      const {data} = serverResponse
      if (!!data) {
        postData = god(data, "response.pageData.pathData")
        pageTemplate = god(data, "response.pageData.pageTemplate")
      }
    } catch (error) {
      BugReportManager.captureException(error)
    }

    if (!!postData) {
      success = true
      if (postDataPathsRequestedInSession.indexOf(path) == -1) {
        postDataPathsRequestedInSession.push(path)
      }
    }

    dispatch({type: actionsConsts.RECEIVE_POST_DATA, path, success, pageTemplate, data: postData})
    return {success, pageTemplate, data: postData}
  }
let postDataPathsRequestedInSession = [] // avoid double calls in same session

// eslint-disable-next-line
export const refreshGeoInfos = () => async dispatch => {
  // debby("refreshGeoInfos()..")
  let countryCode = null
  /*
  try {
    const response = await axios.get(`https://curtisaudiocafe.com/reserva/api/geo`)
    const geoInfos = god(response.data, "geoInfos")
    const geoInfosValidForLogin = god(response.data, "countryValidForLogin")
    const geoInfosValidForReservation = god(response.data, "countryValidForReservation")
    debby("refreshGeoInfos() done.", {geoInfos})
    countryCode = god(response.data, "geoInfos.country")
    if (!!countryCode) {
      dispatch(setAnalyticsUserProperty("country", countryCode)) // AAnalytics.js
    }
    dispatch({type: actionsConsts.RECEIVE_GEO_INFOS, geoInfos, geoInfosValidForLogin, geoInfosValidForReservation})
  } catch (error) {
    BugReportManager.captureException(error)
  }
  */
  return {countryCode}
}

export const requestWpUid = () => async dispatch => {
  let wpUid = 0

  // not working because "root cookie" WP plugin not compatible with installation
  try {
    const serverResponse = await axios.get(`${CONFIG.server.root}/api/wpuid`)
    const {data} = serverResponse
    if (!!data) {
      wpUid = god(data, "response.wpUid")
    }
    if (__DEV__ && !wpUid) {
      wpUid = 1
    }
  } catch (error) {
    BugReportManager.captureException(error)
  }

  dispatch({type: actionsConsts.RECEIVE_WP_UID, wpUid})
  return wpUid
}

export const requestSitemap = () => async dispatch => {
  debby("requestSitemap()..")
  // dispatch({type: actionsConsts.REQUEST_SITEMAP})
  const serverResponse = await axios.get(`${CONFIG.server.root}/api/sitemap`)
  const sitemapDataServer = god(serverResponse, "data.data") // Array of: {pageTemplate, langs: {en: {path}, es: {path}, ..}}
  dispatch({type: actionsConsts.RECEIVE_SITEMAP, data: sitemapDataServer}) // -> routes.sitemap.data
  debby("requestSitemap() done.", {sitemapDataExists: !!sitemapDataServer})
}
