// import {push} from "connected-react-router"
// import BugReportManager from "../../data/utils/BugReportManager"

import * as actionsConsts from "../../data/actionsConsts"

import {debbify, getObjectDeep as god} from "../../data/selectors/helpers" // eslint-disable-line
const debby = (...args) => debbify("ADevice", ...args)

export const setStageDimensions = (width, height) => dispatch => {
  dispatch({type: actionsConsts.SET_STAGE_DIMENSIONS, width, height})
}

export const setTabVisible = visible => dispatch => {
  debby("setTabVisible()", {visible})
  dispatch({type: actionsConsts.SET_TAB_VISIBLE, visible})
}
