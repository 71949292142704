import React, {Component} from "react"
import PropTypes from "prop-types"
import isEqual from "lodash/isEqual"
import {createSelector} from "reselect" // eslint-disable-line
import styled, {css, ThemeProvider} from "styled-components" // eslint-disable-line

import * as selectors from "../../data/selectors"

const DEBUG = false && __DEV__
import {debbify, getObjectDeep as god} from "../../data/selectors/helpers" // eslint-disable-line
const debby = (...args) => debbify("CContentContainerWithPadding", ...args)

class CContentContainerWithPadding extends Component {
  static propTypes = {
    paddingVariant: PropTypes.string,
    children: PropTypes.any,
  }
  static defaultProps = {
    paddingVariant: "content",
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  render = () => {
    const {paddingVariant, children} = this.props
    const d = {debug: DEBUG.toString()}
    debby("render()", {paddingVariant})
    return (
      <ThemeProvider theme={{}}>
        <PaddedContainer {...d} paddingVariant={paddingVariant}>
          {children}
        </PaddedContainer>
      </ThemeProvider>
    )
  }
}

const PaddedContainer = styled.div`
  // border: ${props => (props.debug == "true" ? 5 : 0)}px solid green;
  // background-color: yellow;
  position: relative;
  margin: 0;
  padding: 0;
  ${props => selectors.getPaddingLeftRightByThemeAndVariant(props.theme, props.paddingVariant)}
`

export default CContentContainerWithPadding
