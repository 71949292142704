import React, {Component} from "react"
import PropTypes from "prop-types"
import isEqual from "lodash/isEqual"
import {injectIntl} from "react-intl"
import styled, {css, ThemeProvider} from "styled-components" // eslint-disable-line
import {Link} from "react-router-dom" // eslint-disable-line
import ReactPlayer from "react-player"

import {CONFIG} from "../../config" // eslint-disable-line
import * as selectors from "../../data/selectors"

const DEBUG = false && __DEV__
import {debbify, getObjectDeep as god} from "../../data/selectors/helpers"
const debby = (...args) => debbify("CHero", ...args)

const VIDEOS = {
  showreel: {
    desktop: {
      m4v: `https://zoomsport.com/vid/ffmpeg/3_Showreel_720.m4v`,
      webm: `https://zoomsport.com/vid/ffmpeg/3_Showreel_720.webm`,
      ogv: `https://zoomsport.com/vid/ffmpeg/3_Showreel_720.ogv`,
    },
    mobile: {
      m4v: `https://zoomsport.com/vid/ffmpeg/3_Showreel_360.m4v`,
      webm: `https://zoomsport.com/vid/ffmpeg/3_Showreel_360.webm`,
      ogv: `https://zoomsport.com/vid/ffmpeg/3_Showreel_360.ogv`,
    },
    xl: {
      m4v: `https://zoomsport.com/vid/ffmpeg/3_Showreel_1080.m4v`,
      webm: `https://zoomsport.com/vid/ffmpeg/3_Showreel_1080.webm`,
      ogv: `https://zoomsport.com/vid/ffmpeg/3_Showreel_1080.ogv`,
    },
  },
}

class CHero extends Component {
  static propTypes = {
    intl: PropTypes.object.isRequired,
    mediaQueryClass: PropTypes.string.isRequired,
    posterImageShowreel: PropTypes.object,
    videoLightboxShown: PropTypes.bool,
    onShowreelClick: PropTypes.func,
  }

  constructor(props) {
    super(props)
    this.state = {
      slideIndex: 0,
    }
  }

  componentDidMount = () => {}

  componentWillUnmount = () => {}

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  getVideoQualityKey = () => {
    const {mediaQueryClass} = this.props
    const useXl = selectors.getMediaQueryMatching(mediaQueryClass, ">=", "lg") // eslint-disable-line
    let qualityKey = !selectors.getMediaQueryMatching(mediaQueryClass, "<", "md") ? "desktop" : "mobile"
    const pxWidth = window.innerWidth * window.devicePixelRatio // eslint-disable-line
    if (pxWidth >= 1920) {
      qualityKey = "xl"
    }
    debby("getVideoQualityKey()", {mediaQueryClass, pxWidth, qualityKey})
    return qualityKey
  }

  getVideoById = id => {
    const video = VIDEOS[id]
    const qualityKey = this.getVideoQualityKey()
    const {slideIndex} = video
    const videoUrls = video[qualityKey]
    debby("getVideoById()", {id, qualityKey, slideIndex, videoUrls})
    return {id, slideIndex, ...videoUrls}
  }

  renderSlideShowreelVideo = slideIndex => {
    const video = this.getVideoById("showreel")
    const posterImageUrl = god(this.props.posterImageShowreel, ["1000u", "url"])
    return this.renderSlideVideo({video, posterImageUrl, slideIndex})
  }

  // renderSlideVideo = ({slideIndex, video, posterImageUrl, videoUrlPostfix = "#t=0.001"}) => {
  renderSlideVideo = ({slideIndex, video, posterImageUrl, videoUrlPostfix = ""}) => {
    const {videoLightboxShown} = this.props
    debby("renderSlideVideo()", {slideIndex, posterImageUrl})
    return (
      <SlideVideo
        //
        key={`video_${slideIndex}`}
        index={slideIndex}
        posterImageUrl={posterImageUrl}
        playing={!videoLightboxShown}
        url={[
          {src: video.m4v + videoUrlPostfix, type: "video/mp4"},
          {src: video.webm + videoUrlPostfix, type: "video/webm"},
          {src: video.ogv + videoUrlPostfix, type: "video/ogg"},
        ]}
      />
    )
  }

  showShowreel = () => {
    const {onShowreelClick} = this.props
    debby("showShowreel()")
    !!onShowreelClick && onShowreelClick()
  }

  render = () => {
    const {intl, mediaQueryClass} = this.props // eslint-disable-line
    // const sliderSettings = this.getSliderSettings()
    const d = {debug: DEBUG.toString()}
    debby("render()")
    let aspectRatio = 16 / 9
    if (selectors.getMediaQueryMatching(mediaQueryClass, "<=", "sm")) {
      // sm, xs
      aspectRatio = 4 / 2.65
    }
    const sliderItemProps = {aspectRatio}
    return (
      <Container {...d}>
        <SliderContainer {...d}>
          <SliderItem {...d} {...sliderItemProps}>
            <SliderItemContent {...d} onClick={this.showShowreel}>
              {this.renderSlideShowreelVideo(0)}
            </SliderItemContent>
          </SliderItem>
        </SliderContainer>
      </Container>
    )
  }
}

//////////////////////////////////

class SlideVideo extends Component {
  static propTypes = {
    index: PropTypes.number.isRequired,
    playing: PropTypes.bool,
    posterImageUrl: PropTypes.string,
    url: PropTypes.any,
    onEnded: PropTypes.func,
  }

  constructor(props) {
    super(props)
    this.state = {
      hasProgress: false, // controls opacity
    }
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  componentDidMount = () => {
    // eslint-disable-next-line
    if (true) {
      this.tryToPlayIntervalId = setInterval(() => {
        this.tryToPlay()
      }, 3000)
    }
  }

  tryToPlay = () => {
    if (!this.styledVideoPlayer) {
      debby("tryToPlay(): Missing this.styledVideoPlayer")
    } else if (!this.styledVideoPlayer.player) {
      debby("tryToPlay(): Missing this.styledVideoPlayer.player")
    } else {
      debby("tryToPlay(): Playing..")
      this.styledVideoPlayer.player.player.play()
    }
  }

  tryToPause = () => {
    if (!this.styledVideoPlayer) {
      debby("tryToPause(): Missing this.styledVideoPlayer")
    } else if (!this.styledVideoPlayer.player) {
      debby("tryToPause(): Missing this.styledVideoPlayer.player")
    } else {
      debby("tryToPause(): Playing..")
      this.styledVideoPlayer.player.player.pause()
    }
  }

  componentWillUnmount = () => {
    clearTimeout(this.onVideoEndedCallbackTimeoutId)
    clearInterval(this.tryToPlayIntervalId)
  }

  UNSAFE_componentWillReceiveProps = nextProps => {
    if (nextProps.playing && !this.props.playing) {
      this.tryToPlay()
    } else if (!nextProps.playing && this.props.playing) {
      this.tryToPause()
    }
  }

  onVideoReady = () => {
    const {index} = this.props
    debby("onVideoReady()", {index})
  }
  onVideoBuffer = () => {
    const {index} = this.props
    debby("onVideoBuffer()", {index})
  }
  onVideoBufferEnd = () => {
    const {index} = this.props
    debby("onVideoBufferEnd()", {index})
  }
  onVideoStart = () => {
    const {index} = this.props
    debby("onVideoStart()", {index})
  }
  onVideoPlay = () => {
    const {index} = this.props
    debby("onVideoPlay()", {index})
  }
  onVideoPause = () => {
    const {index} = this.props
    debby("onVideoPause()", {index})
    // this.setState({hasProgress: false})
  }
  onVideoDuration = duration => {
    const {index} = this.props
    debby("onVideoDuration()", {index, duration})
    // this.setState({hasProgress: false})
  }
  onVideoProgress = data => {
    const {index} = this.props
    const hasProgress = !!data.played || this.state.hasProgress
    if (hasProgress == this.state.hasProgress) {
      return
    }
    if (hasProgress) {
      clearInterval(this.tryToPlayIntervalId)
    }
    debby("onVideoProgress()", {index, ...data})
    this.setState({hasProgress})
  }
  onVideoEnded = () => {
    const {index} = this.props
    debby("onVideoEnded()", {index})
  }
  onVideoError = error => {
    debby("onVideoError()", {error}, true)
  }

  render = () => {
    // const {hasProgress} = this.state
    const {playing, posterImageUrl, url} = this.props
    const d = {debug: DEBUG.toString()}
    const hasProgress = /*this.props.playing == true && */ this.state.hasProgress == true
    debby("SlideVideo.render()", {playing, hasProgress, posterImageUrl})
    return (
      <PlayerGeneralContainer {...d}>
        <ReactPlayerContainer {...d}>
          <StyledReactPlayer
            key={`HERO`}
            {...d}
            config={{attributes: {tabIndex: "-1"}}}
            // key={`video_${slideIndex}`}
            ref={obj => (this.styledVideoPlayer = obj)}
            // playing={videoShouldPlay && enablePlayback}
            // playing={this.state.slideIndex == slideIndex}
            // playing={playing}
            playing={true}
            // playing={false}
            width={"100%"}
            height={"100%"}
            muted
            loop
            playsinline
            // light={posterImageUrl}
            progressInterval={100}
            // ref={obj => (this.styledVideoPlayer = obj)}
            onReady={this.onVideoReady}
            onStart={this.onVideoStart}
            onPlay={this.onVideoPlay}
            onProgress={this.onVideoProgress}
            onDuration={this.onVideoDuration}
            onBuffer={this.onVideoBuffer}
            onBufferEnd={this.onVideoBufferEnd}
            onPause={this.onVideoPause}
            onEnded={this.onVideoEnded}
            onError={this.onVideoError}
            url={url}
          />
          {true && (
            <PosterOverlay
              //
              backgroundImageUrl={posterImageUrl}
              hasProgress={hasProgress.toString()}
            />
          )}
        </ReactPlayerContainer>
      </PlayerGeneralContainer>
    )
  }
}

const Container = styled.div`
  position: relative;
  width: 100%;
  // min-height: 500px;
  // background-color: #e5e5e5; // same as first section
  background-color: black; // same as first section
  border: ${props => (props.debug == "true" ? 1 : 0)}px solid red;
`

const SliderContainer = styled.div`
  border: ${props => (props.debug == "true" ? 1 : 0)}px solid green;
  position: relative;
  height: auto;
  padding: 0;
  margin: 0;

  ul.nb-dots {
    opacity: 0.9;
  }
`

const PlayerGeneralContainer = styled.div`
  flex: 1;
  align-self: stretch;
  display: flex;
  padding: 0;
  position: relative;
  width: 100%;
  min-height: 10px;
  min-width: 10px;

  ${props =>
    !!props.backgroundImageUrl &&
    css`
      background-image: url("${props.backgroundImageUrl}");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
    `}
`

const PosterOverlay = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  transition: opacity 0.05s;
  opacity: 1;
  ${props =>
    props.hasProgress == "true" &&
    css`
      opacity: 0;
    `}

  ${props =>
    !!props.backgroundImageUrl &&
    css`
      background-image: url("${props.backgroundImageUrl}");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
    `}
`

const ReactPlayerContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: none !important;
`

// eslint-disable-next-line
const StyledReactPlayer = styled(ReactPlayer)`
  border: ${props => (props.debug == "true" ? 1 : 0)}px solid red;
  background-color: rgba(0, 0, 0, 0) !important;
  background-color: none !important;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  > video {
    object-fit: cover;
    background-color: none !important;
  }
`

const SliderItem = styled.div`
  border: ${props => (props.debug == "true" ? 1 : 0)}px solid blue;
  padding-top: ${props => (1 / props.aspectRatio) * 100}%;
  position: relative;
  background-color: ${props => props.theme.vars.colors.jaguar};
  margin: 0;
`

const SliderItemContent = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`
export default injectIntl(CHero)
