import React, {Fragment, Component} from "react"
import PropTypes from "prop-types"
import isEqual from "lodash/isEqual"
import {createSelector} from "reselect" // eslint-disable-line
import styled, {css, ThemeProvider} from "styled-components" // eslint-disable-line
import Fade from "react-reveal/Fade" // eslint-disable-line

import * as selectors from "../../data/selectors"

const DEBUG = false && __DEV__
import {debbify, getObjectDeep as god} from "../../data/selectors/helpers" // eslint-disable-line
const debby = (...args) => debbify("CAbout", ...args)

class CAbout extends Component {
  static propTypes = {
    header: PropTypes.string,
    subheader: PropTypes.string,
    content: PropTypes.string,
    footer: PropTypes.string,
    logos: PropTypes.array,
    mediaQueryClass: PropTypes.oneOf(["xl", "lg", "md", "sm", "xs"]).isRequired,
    onProjectClick: PropTypes.func.isRequired,
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  onHtmlClick = event => {
    event.preventDefault() // always
    const {onProjectClick} = this.props
    const href = selectors.getHrefByClickEvent(event)
    if (!!href) {
      if (href.indexOf("#") != -1) {
        const anchor = href.substr(href.indexOf("#") + 1)
        !!onProjectClick && onProjectClick({slug: anchor})
        // alert(anchor)
      } else {
      }
    }
  }

  render = () => {
    const {header, subheader, content, footer, logos, mediaQueryClass} = this.props // eslint-disable-line
    if (!header) {
      return <div />
    }
    const d = {debug: DEBUG.toString()}
    // const amountLogos = !!logos ? logos.length : 0
    const amountLogos = 0
    // const amountRows = Math.ceil(amountLogos / 5) // 5 per row

    let amountItemsPerRow = 5
    switch (mediaQueryClass) {
      case "md":
      case "sm":
      case "xs": {
        amountItemsPerRow = 3
        break
      }
    }
    const rows = !!logos && !!logos.length ? selectors.getArrayChunks(logos, amountItemsPerRow) : null
    debby("render()", {amountLogos, mediaQueryClass, amountItemsPerRow, rows})
    return (
      <ThemeProvider theme={{}}>
        <Container {...d}>
          <Header {...d} dangerouslySetInnerHTML={{__html: header}} />
          {!!subheader && <Subheader {...d} dangerouslySetInnerHTML={{__html: subheader}} />}
          {!!content && <Content {...d} dangerouslySetInnerHTML={{__html: content}} onClick={this.onHtmlClick} />}
          {!!footer && <Footer {...d} dangerouslySetInnerHTML={{__html: footer}} onClick={this.onHtmlClick} />}
          {!!logos && !!logos.length && false && (
            <LogosContainer {...d}>
              {logos.map((item, i) => {
                return (
                  <Fragment key={`logo_${i}`}>
                    <SpacerContainer {...d} />
                    <LogoContainer {...d} scale={item.scale}>
                      <LogoImage {...d} src={god(item, ["sizes", "home-logo-md", "url"])} scale={item.scale} />
                    </LogoContainer>
                    {i == logos.length - 1 && <SpacerContainer {...d} />}
                  </Fragment>
                )
              })}
            </LogosContainer>
          )}
          <CAboveLogoSpacer {...d} />
          {!!rows &&
            rows.map((row, rowIndex) => {
              return (
                <LogosContainer {...d} key={`logoRow_${rowIndex}`}>
                  {row.map((item, i) => {
                    return (
                      <Fragment key={`logo_${i}`}>
                        <SpacerContainer {...d} />
                        <LogoContainer {...d} scale={item.scale}>
                          <LogoImage {...d} src={god(item, ["sizes", "home-logo-md", "url"])} scale={item.scale} />
                        </LogoContainer>
                        {i == row.length - 1 && <SpacerContainer {...d} />}
                      </Fragment>
                    )
                  })}
                </LogosContainer>
              )
            })}
        </Container>
      </ThemeProvider>
    )
  }
}

const Container = styled.div`
  position: relative;
  overflow: hidden;
  border: ${props => (props.debug == "true" ? 3 : 0)}px solid red;
  background-color: #000;
  color: white;

  padding: 100px 150px 70px 150px;
  ${props => css`
    ${props.theme.media.lg} {
      padding: 80px 70px 70px 70px;
    }
  `}
  ${props => css`
    ${props.theme.media.md} {
      padding: 80px 80px 70px 80px;
    }
  `}
  ${props => css`
    ${props.theme.media.sm} {
      padding: 60px 40px 70px 40px;
    }
  `}
  ${props => css`
    ${props.theme.media.xs} {
      padding: 30px 40px 70px 40px;
    }
  `}
`

const HeaderSizeXl = 100
const SubeaderSizeXl = 62
const ContentSizeXl = 37
const FooterSizeXl = 54

const Header = styled.div`
  font-weight: 500;
  em,
  i {
    font-style: italic;
  }
  border: ${props => (props.debug == "true" ? 1 : 0)}px solid red;

  font-size: ${HeaderSizeXl}px;
  ${props => css`
    ${props.theme.media.xs} {
      font-size: ${HeaderSizeXl * 0.4}px;
    }
  `}
  ${props => css`
    ${props.theme.media.sm} {
      font-size: ${HeaderSizeXl * 0.5}px;
    }
  `}
  ${props => css`
    ${props.theme.media.md} {
      font-size: ${HeaderSizeXl * 0.5}px;
    }
  `}
  ${props => css`
    ${props.theme.media.lg} {
      font-size: ${HeaderSizeXl * 0.7}px;
    }
  `}
  padding-bottom: 30px;
`

const Subheader = styled.div`
  color: white;
  font-weight: 300;

  > p {
    padding-bottom: 30px;

    ** > a {
      color: white !important;
    }
  }

  a,
  > a,
  p > a {
    color: white !important;
  }

  em,
  i {
    font-style: italic;
  }
  border: ${props => (props.debug == "true" ? 1 : 0)}px solid green;

  font-size: ${SubeaderSizeXl}px;
  ${props => css`
    ${props.theme.media.xs} {
      font-size: ${SubeaderSizeXl * 0.4}px;
    }
  `}
  ${props => css`
    ${props.theme.media.sm} {
      font-size: ${SubeaderSizeXl * 0.5}px;
    }
  `}
  ${props => css`
    ${props.theme.media.md} {
      font-size: ${SubeaderSizeXl * 0.5}px;
    }
  `}
  ${props => css`
    ${props.theme.media.lg} {
      font-size: ${SubeaderSizeXl * 0.7}px;
    }
  `}
`

const Content = styled.div`
  color: white;
  // line-height: 41px;
  font-weight: 300;

  > a,
  * > a,
  p > a {
    color: white !important;
  }
  em,
  i {
    font-style: italic;
  }
  border: ${props => (props.debug == "true" ? 1 : 0)}px solid blue;

  font-size: ${ContentSizeXl}px;
  > p {
    padding: 20px 0px;
  }
  ${props => css`
    ${props.theme.media.xs} {
      font-size: ${ContentSizeXl * 0.4}px;
      > p {
        padding: 10px 0px;
      }
    }
  `}
  ${props => css`
    ${props.theme.media.sm} {
      font-size: ${ContentSizeXl * 0.5}px;
      > p {
        padding: 10px 0px;
      }
    }
  `}
  ${props => css`
    ${props.theme.media.md} {
      font-size: ${ContentSizeXl * 0.5}px;
      > p {
        padding: 10px 0px;
      }
    }
  `}
  ${props => css`
    ${props.theme.media.lg} {
      font-size: ${ContentSizeXl * 0.7}px;
      > p {
        padding: 15px 0px;
      }
    }
  `}
`

const Footer = styled.div`
  color: white;
  // line-height: 41px;
  font-weight: 300;

  > p {
    // padding: 20px 0px;
  }

  > strong {
    font-weight: bold;
  }

  em,
  i {
    font-style: italic;
  }
  border: ${props => (props.debug == "true" ? 1 : 0)}px solid yellow;

  font-size: ${FooterSizeXl}px;
  padding-top: 70px;
  ${props => css`
    ${props.theme.media.xs} {
      font-size: ${FooterSizeXl * 0.4}px;
      padding-top: 30px;
    }
  `}
  ${props => css`
    ${props.theme.media.sm} {
      font-size: ${FooterSizeXl * 0.5}px;
      padding-top: 40px;
    }
  `}
  ${props => css`
    ${props.theme.media.md} {
      font-size: ${FooterSizeXl * 0.5}px;
      padding-top: 50px;
    }
  `}
  ${props => css`
    ${props.theme.media.lg} {
      font-size: ${FooterSizeXl * 0.7}px;
    }
  `}
`

const CAboveLogoSpacer = styled.div`
  background-color: ${props => (props.debug == "true" ? "yellow" : "none")};

  height: 50px;
  ${props => css`
    ${props.theme.media.lg} {
      height: 90px;
    }
  `}
  ${props => css`
    ${props.theme.media.xl} {
      height: 110px;
    }
  `}
`

const LogosContainer = styled.div`
  // padding-right: 200px; // same as icons col
  color: white;
  border: ${props => (props.debug == "true" ? 1 : 0)}px solid blue;
  display: flex;
  flex-direction: row;

  padding-top: 50px;
  ${props => css`
    ${props.theme.media.xs} {
      padding-top: 30px;
    }
  `}
  ${props => css`
    ${props.theme.media.sm} {
      // padding-top: 70px;
    }
  `}
  ${props => css`
    ${props.theme.media.md} {
      // padding-top: 90px;
    }
  `}
  ${props => css`
    ${props.theme.media.lg} {
      // padding-top: 100px;
    }
  `}
`

const SpacerContainer = styled.div`
  border: ${props => (props.debug == "true" ? 1 : 0)}px solid yellow;
  background-color: ${props => (props.debug == "true" ? "red" : "none")};
  flex: 1;
  ${props => css`
    ${props.theme.media.smdown} {
      flex: 0.5;
    }
  `}
`

const LogoContainer = styled.div`
  border: ${props => (props.debug == "true" ? 1 : 0)}px solid white;
  // flex: ${props => 2 * (parseFloat(props.scale) || 1)};
  // width: ${props => (parseFloat(props.scale) || 1) * 100}px;
  align-self: stretch;
  // width: 70px;
  // height: 70px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100px;
  min-height: 100px;
  ${props => css`
    ${props.theme.media.xs} {
      width: 50px;
      min-height: 50px;
    }
  `}
`

const LogoImage = styled.img`
  position: relative;
  width: 100%;
  height: auto;
  transform: scale(${props => parseFloat(props.scale) || 1});
  object-fit: contain;
  ${props =>
    props.debug == "true" &&
    false &&
    css`
      background-color: yellow;
    `}
`

export default CAbout
