import React, {Component, Fragment} from "react"
import PropTypes from "prop-types"
import isEqual from "lodash/isEqual"
import {connect} from "react-redux"
import {createSelector} from "reselect"
import {injectIntl} from "react-intl"
import styled, {ThemeProvider, css} from "styled-components" // eslint-disable-line

import CHeader from "../../../view/components/CHeader"
import CWpEditButton from "../../../view/components/CWpEditButton"
import CHero from "../../../view/components/CHero"
import CElementY from "../../../view/components/CElementY"
import CMenu from "../../../view/components/CMenu"
import CSocialIcons from "../../../view/components/CSocialIcons"
import CFilms from "../../../view/components/CFilms"
import CAbout from "../../../view/components/CAbout"
import CTeamMembers from "../../../view/components/CTeamMembers"

import {HiddenH1} from "../../../view/components/Styled"

import * as selectors from "../../../data/selectors"
import * as actions from "../../../data/actions"
import {debbify, getObjectDeep as god, wait} from "../../../data/selectors/helpers"
const debby = (...args) => debbify("SMain", ...args)

const DEBUG = false && __DEV__
const SHOW_ONLY_FIRST_PROJECT = false && __DEV__

class SMain extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    tabVisible: PropTypes.bool,
    mediaQueryClass: PropTypes.oneOf(["xl", "lg", "md", "sm", "xs"]).isRequired,
    wpUid: PropTypes.number,
    postData: PropTypes.object,
    projects: PropTypes.array,
    videoLightboxShown: PropTypes.bool,
    elementsY: PropTypes.object,
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  renderMenu = () => {
    return <CMenu screen={"main"} />
  }

  renderSocialIcons = () => {
    const {postData} = this.props
    return (
      <CSocialIcons
        //
        urlFacebook={god(postData, "sectionContactV2.facebook")}
        urlInstagram={god(postData, "sectionContactV2.instagram")}
        urlTwitter={god(postData, "sectionContactV2.twitter")}
        urlVimeo={god(postData, "sectionContactV2.vimeo")}
      />
    )
  }

  renderHero = () => {
    const {mediaQueryClass, postData} = this.props
    const d = {debug: DEBUG.toString()}
    return (
      <Fragment>
        <CElementY screen={"main"} section={"hero"} />
        <ContainerHero {...d}>
          <CHero
            //
            mediaQueryClass={mediaQueryClass}
            posterImageShowreel={god(postData, "sectionHeader.posterImageShowreel")}
            onVideoClick={this.showShowreel}
            videoLightboxShown={this.props.videoLightboxShown}
            onShowreelClick={this.onHeroShowreelClick}
          />
        </ContainerHero>
      </Fragment>
    )
  }

  onHeroShowreelClick = async () => {
    const {dispatch, elementsY} = this.props
    const key = "films"
    const elementY = god(elementsY, key, -1)
    debby("onHeroShowreelClick()", {key, elementY})
    if (elementY == -1) {
      return
    }
    dispatch(actions.scrollToY(elementY))
    await wait(1000) // until scrolling finished
    this.onProjectItemClick({slug: "showreel"})
  }

  renderFilms = () => {
    const {projects, tabVisible, mediaQueryClass} = this.props
    const d = {debug: DEBUG.toString()}
    if (!projects || !projects.length) {
      return null
    }
    return (
      <Fragment>
        <CElementY screen={"main"} section={"films"} />
        <ContainerFilms {...d}>
          <CFilms
            //
            items={projects}
            tabVisible={tabVisible}
            mediaQueryClass={mediaQueryClass}
            onItemClick={this.onProjectItemClick}
          />
        </ContainerFilms>
      </Fragment>
    )
  }

  renderAbout = () => {
    const {postData, mediaQueryClass} = this.props
    const d = {debug: DEBUG.toString()}
    const header = god(postData, "sectionAbout.header")
    const subheader = god(postData, "sectionAbout.subheader") // eslint-disable-line
    const content = god(postData, "sectionAbout.content")
    const footer = god(postData, "sectionAbout.footer")
    const logos = god(postData, "sectionAbout.logos")
    return (
      <Fragment>
        <CElementY screen={"main"} section={"about"} />
        <ContainerAbout {...d}>
          <CAbout
            //
            header={header}
            subheader={subheader}
            content={content}
            footer={footer}
            logos={logos}
            mediaQueryClass={mediaQueryClass}
            onProjectClick={this.onAboutProjectClick}
          />
        </ContainerAbout>
      </Fragment>
    )
  }

  onAboutProjectClick = ({slug}) => {
    debby("onAboutProjectClick()", {slug})
    this.onProjectItemClick({slug})
  }

  onProjectItemClick = ({slug}) => {
    const {dispatch, projects} = this.props
    const project = !!projects ? projects.find(p => p.slug == slug) : null
    if (!!project) {
      const vimeoUrl = god(project, "video.vimeoUrl")
      const title = god(project, "title")
      debby("onProjectItemClick()", {slug, vimeoUrl, title})
      !!vimeoUrl && dispatch(actions.showVideoLightbox({mode: "vimeo", slug, url: vimeoUrl, title, allItems: projects}))
    }
  }

  renderTeamMembers = () => {
    const {postData} = this.props
    const members = god(postData, "sectionTeamV2.members")
    if (!members || !members.length) {
      return null
    }
    const contactHeader = god(postData, "sectionContactV2.header")
    const contactAddress1 = god(postData, "sectionContactV2.address1")
    const contactAddress2 = god(postData, "sectionContactV2.address2")
    const contactInstagramHandle = god(postData, "sectionContactV2.instagramHandle")
    const contactInstagram = god(postData, "sectionContactV2.instagram")
    const d = {debug: DEBUG.toString()}

    return (
      <Fragment>
        <CElementY screen={"main"} section={"team"} />
        <ContainerTeam {...d}>
          <CTeamMembers
            //
            items={members}
            contactHeader={contactHeader}
            contactAddress1={contactAddress1}
            contactAddress2={contactAddress2}
            contactInstagramHandle={contactInstagramHandle}
            contactInstagram={contactInstagram}
            urlFacebook={god(postData, "sectionContactV2.facebook")}
            urlInstagram={god(postData, "sectionContactV2.instagram")}
            urlTwitter={god(postData, "sectionContactV2.twitter")}
            urlVimeo={god(postData, "sectionContactV2.vimeo")}
            contactTwitterHandle={god(postData, "sectionContactV2.twitterHandle")}
          />
        </ContainerTeam>
      </Fragment>
    )
  }

  render = () => {
    const {postData, wpUid, tabVisible} = this.props
    const d = {debug: DEBUG.toString()}
    debby("render()", {tabVisible})
    return (
      <ThemeProvider theme={{}}>
        <Container {...d}>
          {true && this.renderHero()}
          <HiddenH1>Zoomsport</HiddenH1>
          {true && this.renderFilms()}
          {true && this.renderAbout()}
          {true && this.renderTeamMembers()}
          {!!wpUid && false && (
            <div style={{position: "fixed", left: 10, top: 0, bottom: 0, display: "flex", alignItems: "center"}}>
              <CWpEditButton postId={god(postData, "id")} />
            </div>
          )}
        </Container>
        {false && <CHeader screen={"main"} />}
        {this.renderMenu()}
        {true && this.renderSocialIcons()}
      </ThemeProvider>
    )
  }
}

const Container = styled.div`
  position: relative;
  max-width: ${props => props.theme.vars.maxWidth.container}px;
  margin: 0 auto;
  border: ${props => (props.debug == "true" ? 1 : 0)}px solid red;
`

const ContainerHero = styled.div`
  background-color: ${props => props.theme.vars.colors.heroBg};
  border: ${props => (props.debug == "true" ? 1 : 0)}px solid green;
  ${props => selectors.getDebugOverlayCss(props, "ContainerHero", "rgba(255,0,0,0.35)")}
`

const ContainerFilms = styled.div`
  border: ${props => (props.debug == "true" ? 1 : 0)}px solid blue;
  ${props => selectors.getDebugOverlayCss(props, "ContainerFilms", "rgba(0,255,0,0.35)")}
`

const ContainerAbout = styled.div`
  ${props => selectors.getDebugOverlayCss(props, "ContainerAbout", "rgba(0,255,255,0.35)")}
`

const ContainerTeam = styled.div`
  position: relative;
  border: ${props => (props.debug == "true" ? 1 : 0)}px solid blue;
  ${props => selectors.getDebugOverlayCss(props, "ContainerTeam", "rgba(255,255,0,0.35)")}
`

const getProjects = createSelector([selectors.getPostData], postData => {
  if (!postData || !postData.sectionProjectsV2 || !postData.sectionProjectsV2.projects || !postData.sectionProjectsV2.projects.length) {
    return null
  }
  const projects = !!postData && !!postData.sectionProjectsV2 && !!postData.sectionProjectsV2.projects && !!postData.sectionProjectsV2.projects.length ? (!SHOW_ONLY_FIRST_PROJECT ? postData.sectionProjectsV2.projects : postData.sectionProjectsV2.projects.slice(0, 1)) : null
  return projects.map((p, i) => ({...p, totalIndex: i}))
})

const getElementsYByKey = state => state.layout.elementsY

const getElementsY = createSelector([getElementsYByKey], elementsYByKey => {
  return god(elementsYByKey, "main", {})
})

const mapStateToProps = (state, props) => ({
  tabVisible: state.device.tabVisible,
  mediaQueryClass: selectors.getMediaQueryClass(state),
  wpUid: state.device.wpUid,
  postData: selectors.getPostData(state, props),
  projects: getProjects(state, props),
  videoLightboxShown: state.routing.videoLightboxShown || false,
  elementsY: getElementsY(state),
})
export default injectIntl(connect(mapStateToProps)(SMain))
