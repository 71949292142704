import React, {Component} from "react"
import PropTypes from "prop-types"
import isEqual from "lodash/isEqual"
// import {createSelector} from "reselect"
import styled, {css, ThemeProvider} from "styled-components" // eslint-disable-line
import ReactPlayer from "react-player/lazy"
import Color from "color"
import Fade from "react-reveal/Fade" // eslint-disable-line

import BugReportManager from "../../data/utils/BugReportManager"

// import {PaddedContentMaxWidth} from "../../view/styled"

import * as selectors from "../../data/selectors"

const DEBUG = false && __DEV__
import {debbify, getObjectDeep as god} from "../../data/selectors/helpers" // eslint-disable-line
const debby = (...args) => debbify("CProjectItem", ...args)

class CProjectItem extends Component {
  static propTypes = {
    enablePlayback: PropTypes.bool,
    totalIndex: PropTypes.number.isRequired,
    id: PropTypes.number.isRequired,
    slug: PropTypes.string.isRequired,
    videoFileKey: PropTypes.string.isRequired,
    video: PropTypes.shape({
      poster: PropTypes.shape({
        sizes: PropTypes.object.isRequired,
      }).isRequired,
    }).isRequired,
    overlay: PropTypes.shape({
      logos: PropTypes.array,
      title: PropTypes.string.isRequired,
    }).isRequired,
    showVideo: PropTypes.bool,
    onClick: PropTypes.func,
    mediaQueryClass: PropTypes.oneOf(["xl", "lg", "md", "sm", "xs"]).isRequired,
    aspectRatio: PropTypes.number,
    useFullHeight: PropTypes.bool,
    fixedHoverBackgroundColor: PropTypes.string,
    fixedHoverTextColor: PropTypes.string,
    onHoverEnter: PropTypes.func.isRequired,
    onHoverLeave: PropTypes.func.isRequired,
  }
  static defaultProps = {
    showVideo: true,
    aspectRatio: 16 / 9,
    useFullHeight: false,
  }

  constructor(props) {
    super(props)
    this.state = {
      videoReady: false,
      videoPlaying: false,
      videoShouldPlay: false, // moved to props
    }
  }

  componentDidMount = () => {
    this.handleProps(this.props)
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  handleProps = nextProps => {
    if (!nextProps.enablePlayback == this.props.enablePlayback) {
      this.setVideoShouldPlay(false, "enable-playback-changed")
    }
  }

  onVideoError = error => {
    debby("onVideoError()", {error}, true)
    BugReportManager.captureException(error)
  }
  onVideoLoadedMetadata = () => {
    const {slug} = this.props
    debby("onVideoLoadedMetadata()", {slug})
  }
  onVideoWaiting = () => {
    const {slug} = this.props
    debby("onVideoWaiting()", {slug})
  }
  onVideoReady = () => {
    const {slug} = this.props
    debby("onVideoReady()", {slug})
    this.setState({videoReady: true})
  }
  onVideoPlay = () => this.setState({videoPlaying: true})
  onVideoPause = () => this.setState({videoPlaying: false})
  onVideoMouseEnter = () => {
    const {slug, onHoverEnter} = this.props
    debby("onVideoMouseEnter()", {slug})
    this.setVideoShouldPlay(true, "mouse-enter")
    !!onHoverEnter && onHoverEnter({slug})
  }
  onVideoMouseLeave = () => {
    const {slug, onHoverLeave} = this.props
    debby("onVideoMouseLeave()", {slug})
    this.setVideoShouldPlay(false, "mouse-leave")
    !!onHoverLeave && onHoverLeave({slug})
  }
  onVideoClick = () => {
    const {slug, onClick} = this.props
    debby("onVideoClick()", {slug})
    if (!!onClick) {
      this.setVideoShouldPlay(false, "click")
      onClick({slug})
    }
  }

  setVideoShouldPlay = (shouldPlay, reason = "?") => {
    const {slug} = this.props
    if (!this.videoPlayer && !this.styledVideoPlayer) {
      debby("setVideoShouldPlay(): VideoPlayer ref not found.", {slug, reason})
      return
    }
    debby("setVideoShouldPlay()", {slug, shouldPlay, reason})
    if (shouldPlay) {
      this.setState({videoShouldPlay: true})

      if (!!this.styledVideoPlayer && !!this.styledVideoPlayer.player) {
        this.styledVideoPlayer.player.player.play() //.catch(this.onVideoError) // eslint-disable-line
      }
      // !!this.styledVideoPlayer && !!this.styledVideoPlayer.player &&
    } else {
      // this.setState({videoShouldPlay: false})
      !!this.styledVideoPlayer && !!this.styledVideoPlayer.player && !!this.styledVideoPlayer.player.player && this.styledVideoPlayer.player.player.pause()
    }
  }

  pause = () => {
    const {slug} = this.props
    debby("pause()", {slug})
    !!this.styledVideoPlayer && !!this.styledVideoPlayer.player && !!this.styledVideoPlayer.player.player && this.styledVideoPlayer.player.player.pause()
  }

  getVideoQualityKey = () => {
    const {mediaQueryClass} = this.props
    const qualityKey = !selectors.getMediaQueryMatching(mediaQueryClass, "<", "md") ? "desktop" : "mobile"
    return qualityKey
  }

  render = () => {
    const {videoReady, videoShouldPlay, videoPlaying} = this.state // eslint-disable-line
    const {videoFileKey, showVideo, mediaQueryClass, enablePlayback, totalIndex, aspectRatio, id, slug, video, overlay, useFullHeight, fixedHoverBackgroundColor, fixedHoverTextColor} = this.props // eslint-disable-line
    if (!video || !id) {
      return <div />
    }
    const {bg: colorBg, fg: colorFg} = selectors.getColorsForProjectByTotalIndex(totalIndex, true) // true: useVersion2Colors
    const d = {debug: DEBUG.toString()}
    const posterImageUrl = god(video, ["poster", "sizes", "1000u", "url"])
    const overlayBackgroundColor = Color(colorBg).alpha(0.5).string()
    const videoQualityKey = this.getVideoQualityKey() // desktop / mobile
    const videoPostfix = videoQualityKey == "desktop" ? "720" : "360"
    const urlPrefix = `https://zoomsport.com/vid/ffmpeg/${videoFileKey}_${videoPostfix}`
    const url = [
      {src: `${urlPrefix}.m4v`, type: "video/mp4"},
      {src: `${urlPrefix}.webm`, type: "video/webm"},
      {src: `${urlPrefix}.ogv`, type: "video/ogg"},
    ]
    const showByMediaQuery = selectors.getMediaQueryMatching(mediaQueryClass, ">=", "md")
    // debby("render()", {videoFileKey, videoPostfix, url, mediaQueryClass, showByMediaQuery, totalIndex, slug, enablePlayback, videoShouldPlay})
    return (
      <ThemeProvider theme={{}}>
        <Fade delay={1000} duration={1000} style={{position: "relative"}}></Fade>
        <Container {...d}>
          <PlayerContainer
            //
            {...d}
            backgroundImageUrl={posterImageUrl}
            aspectRatio={aspectRatio}
            useFullHeight={JSON.stringify(useFullHeight)}
          >
            {showVideo && showByMediaQuery && (
              <StyledReactPlayer
                //
                {...d}
                ref={obj => (this.styledVideoPlayer = obj)}
                id={`video_${slug}`}
                url={url}
                playing={videoShouldPlay && enablePlayback}
                muted
                loop
                width={"100%"}
                height={"100%"}
                playsinline
                onError={this.onVideoError}
                onReady={this.onVideoReady}
                onPlay={this.onVideoPlay}
                onPause={this.onVideoPause}
              />
            )}
          </PlayerContainer>
          <ContentOverlay
            //
            {...d}
            backgroundColor={overlayBackgroundColor}
            onMouseEnter={this.onVideoMouseEnter}
            onMouseLeave={this.onVideoMouseLeave}
            onTouchStart={this.onVideoMouseEnter}
            onTouchEnd={this.onVideoMouseLeave}
            onTouchCancel={this.onVideoMouseLeave}
            hasOnClick={!!this.props.onClick}
            onClick={this.onVideoClick}
          >
            <OverlayTitle
              //
              {...d}
              color={colorFg}
              id={"title"}
              dangerouslySetInnerHTML={{__html: overlay.title}}
            />
          </ContentOverlay>
          {DEBUG && (
            <div style={{pointerEvents: "none", position: "absolute", left: 0, top: 0, right: 0, backgroundColor: "rgba(0,0,0,0.5)", fontSize: 12}}>
              <pre>
                {JSON.stringify(
                  {
                    //
                    enablePlayback,
                    showVideo,
                    showByMediaQuery,
                    videoReady,
                  },
                  null,
                  2,
                )}
              </pre>
            </div>
          )}
        </Container>
      </ThemeProvider>
    )
  }
}

const Container = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
  background-color: black;
  height: 100%;
`

const PlayerContainer = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  border: ${props => (props.debug == "true" ? 1 : 0)}px solid blue;

  ${props =>
    props.useFullHeight == "false" &&
    css`
      padding-top: ${props => (1 / props.aspectRatio) * 100}%;
    `}

  ${props =>
    props.useFullHeight == "true" &&
    css`
      height: 100%;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    `}

  ${props =>
    !!props.backgroundImageUrl &&
    css`
      background-image: url("${props.backgroundImageUrl}");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
    `}
`

const StyledReactPlayer = styled(ReactPlayer)`
  border: ${props => (props.debug == "true" ? 1 : 0)}px solid red;
  background-color: rgba(0, 0, 0, 0) !important;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  > video {
    object-fit: cover;
  }
`

const ContentOverlay = styled.div`
  position: absolute;
  background-color: ${props => props.backgroundColor};
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  ${props =>
    props.hasOnClick &&
    css`
      cursor: pointer;
    `}

  transition: all 0.3s;

  > div#title {
    opacity: ${props => (props.debug == "true" ? 0.5 : 0)};
    // transition: all 0.3s;
    // transition-delay: 0.2s;
    transform: translate3d(0px, ${props => (props.debug == "true" ? 0 : 20)}px, 0px);
    // transform: translate3d(0px, 20px, 0px);
  }

  opacity: ${props => (props.debug == "true" ? 0.5 : 0)};
  :hover {
    opacity: 1;
    > div#title {
      opacity: 1;
      transform: translate3d(0px, 0px, 0px);
    }
  }
`

const OverlayTitle = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  color: ${props => props.color};
  font-weight: 900;
  line-height: 1.1;
  border: ${props => (props.debug == "true" ? 2 : 0)}px solid blue;
  transition: none;

  font-size: 40px;
  > p {
    margin: 0;
    padding: 40px 40px;
  }

  ${props => css`
    ${props.theme.media.lg} {
      font-size: 30px;
      > p {
        padding: 30px;
      }
    }
  `}
  ${props => css`
    ${props.theme.media.md} {
      font-size: 25px;
      > p {
        padding: 18px;
      }
    }
  `}
  ${props => css`
    ${props.theme.media.smdown} {
      font-size: 20px;
      > p {
        padding: 15px;
      }
    }
  `}
`

export default CProjectItem
