import React, {Component} from "react"
import PropTypes from "prop-types"
import isEqual from "lodash/isEqual"
import {connect} from "react-redux"
import styled, {ThemeProvider, css} from "styled-components" // eslint-disable-line
import Measure from "react-measure"

const DEBUG = false && __DEV__ // eslint-disable-line
import {debbify, getObjectDeep as god} from "../../data/selectors/helpers" // eslint-disable-line
const debby = (...args) => debbify("CElementY", ...args)

import * as selectors from "../../data/selectors" // eslint-disable-line
import * as actions from "../../data/actions"

class CElementY extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    screen: PropTypes.string.isRequired,
    section: PropTypes.string.isRequired,
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  componentDidMount = () => {
    this.refreshIntervalId = setInterval(this.refreshMeasure, 1000)
  }
  componentWillUnmount = () => {
    clearInterval(this.refreshIntervalId)
  }
  refreshMeasure = () => !!this.measureRef && !!this.measureRef.measure && this.measureRef.measure()

  onResize = contentRect => {
    const {screen, section} = this.props
    const windowScrollY = selectors.getWindowScrollY() // compensate initial window scroll Y
    const top = contentRect.bounds.top + windowScrollY
    // debby("onResize()", {screen, section, contentRect})
    this.props.dispatch(actions.setLayoutElementY(screen, section, top))
  }

  render = () => {
    const {screen, section} = this.props
    debby("render()", {screen, section})
    return (
      <Measure
        bounds // results in negative values when scrolled down on load
        onResize={this.onResize}
        ref={obj => (this.measureRef = obj)}
      >
        {({measureRef}) => (
          <Container ref={measureRef}>
            {DEBUG && (
              <span>
                {`<`}CElementY.{screen}.{section}
                {`/>`}
              </span>
            )}
          </Container>
        )}
      </Measure>
    )
  }
}

const Container = styled.div`
  background-color: green;
  color: yellow;
  font-size: 10px;
`

const mapStateToProps = () => ({})
export default connect(mapStateToProps)(CElementY)
