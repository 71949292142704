import PropTypes from "prop-types"
import {createSelector} from "reselect"

import {CONFIG} from "../../config"

import {debbify} from "../../data/selectors/helpers"
const debby = (...args) => debbify("layout", ...args) // eslint-disable-line

export const getStageWidth = state => state.device.stageDimensions.width
// export const getSessionWaypointThemeKey = state => state.session.waypointThemeKey

export const getMediaQueryClasses = () => {
  return ["xs", "sm", "md", "lg", "xl"]
}

export const getChildContextTypesGrid = () => ({
  breakpoints: PropTypes.arrayOf(PropTypes.number),
  gutterWidth: PropTypes.number,
  defaultScreenClass: PropTypes.string,
})

export const getChildContextGrid = () => {
  const {breakpoints} = CONFIG.layout
  let breakpointsArray = []
  Object.keys(breakpoints).map(bpKey => {
    const bp = breakpoints[bpKey]
    if (bp.hasOwnProperty("max")) {
      breakpointsArray.push(bp.max)
    }
  })

  return {
    breakpoints: breakpointsArray,
    gutterWidth: 0,
    defaultScreenClass: "xl",
  }
}

export const getMediaQueryClass = createSelector([getStageWidth], stageWidth => {
  // console.log("getMediaQueryClass (): " + JSON.stringify(stageWidth))
  const childContextGrid = getChildContextGrid()
  const classes = getMediaQueryClasses()
  if (!stageWidth) {
    return classes[classes.length - 1] // assume largest screen size
  }

  const {breakpoints} = childContextGrid
  let indexMax = classes.length - 1 // default: xs
  for (var i = breakpoints.length - 1; i >= 0; i--) {
    const breakpointWidthMax = breakpoints[i]
    if (stageWidth < breakpointWidthMax) {
      indexMax = i
    }
  }
  return classes[indexMax]
})

export const getWindowScrollY = () => {
  let supportPageOffset = window.pageYOffset !== undefined
  // let isCSS1Compat = (document.compatMode || "") === "CSS1Compat"
  let scroll = {
    // x: supportPageOffset ? window.pageXOffset : isCSS1Compat ? document.documentElement.scrollLeft : document.body.scrollLeft,
    // y: supportPageOffset ? window.pageYOffset : isCSS1Compat ? document.documentElement.scrollTop : document.body.scrollTop,
    y: supportPageOffset ? window.pageYOffset : document.body.scrollTop,
  }
  const scrollY = scroll.y
  return scrollY
}

export const getColorsForProjectByTotalIndex = (totalIndex, useVersion2Colors = false) => {
  const {colors} = CONFIG.layout
  if (useVersion2Colors) {
    return {bg: colors.fixedProjectBgNew, fg: colors.fixedProjectFg}
  }
  const combos = [
    //
    {bg: colors.tm4bg, fg: colors.tm4fg},
    {bg: colors.tm1bg, fg: colors.tm1fg},
    {bg: colors.tm2bg, fg: colors.tm2fg},
    {bg: colors.tm3bg, fg: colors.tm3fg},
  ]
  const indexInCombos = totalIndex % combos.length
  // debby("getColorsForProjectByTotalIndex()", {totalIndex, indexInCombos})
  return combos[indexInCombos]
}

export const getMediaQueryMatching = (mediaQuery1, operator, mediaQuery2, options = {}) => {
  if (!mediaQuery1) {
    return false
  }

  if (options.debug) { debugger } // prettier-ignore

  const values = {xs: 1, sm: 2, md: 3, lg: 4, xl: 5}
  const value1 = values[mediaQuery1]
  const value2 = values[mediaQuery2]
  switch (operator) {
    case "<":
      return value1 < value2
    case ">":
      return value1 > value2
    case "<=":
      return value1 <= value2
    case ">=":
      return value1 >= value2
    case "=":
    case "==":
      return value1 == value2
  }
  return false
}

export const getDevicePixelRatio = () => {
  var mediaQuery
  var is_firefox = navigator.userAgent.toLowerCase().indexOf("firefox") > -1
  if (window.devicePixelRatio !== undefined && !is_firefox) {
    return window.devicePixelRatio
  } else if (window.matchMedia) {
    mediaQuery = "(-webkit-min-device-pixel-ratio: 1.5),\
          (min--moz-device-pixel-ratio: 1.5),\
          (-o-min-device-pixel-ratio: 3/2),\
          (min-resolution: 1.5dppx)"
    if (window.matchMedia(mediaQuery).matches) {
      return 1.5
    }
    mediaQuery = "(-webkit-min-device-pixel-ratio: 2),\
          (min--moz-device-pixel-ratio: 2),\
          (-o-min-device-pixel-ratio: 2/1),\
          (min-resolution: 2dppx)"
    if (window.matchMedia(mediaQuery).matches) {
      return 2
    }
    mediaQuery = "(-webkit-min-device-pixel-ratio: 0.75),\
          (min--moz-device-pixel-ratio: 0.75),\
          (-o-min-device-pixel-ratio: 3/4),\
          (min-resolution: 0.75dppx)"
    if (window.matchMedia(mediaQuery).matches) {
      return 0.7
    }
  } else {
    return 1
  }
}
