import {css} from "styled-components"
import {CONFIG} from "../../config"

const DEFAULT_MEDIA_QUERIES = Object.keys(CONFIG.layout.breakpoints) // ["xl", "lg", "md", "sm", "xs"]

export const getPaddingLeftRightByThemeAndVariant = (theme, variant = null) =>
  Object.keys(theme.media)
    .filter(mq => DEFAULT_MEDIA_QUERIES.indexOf(mq) !== -1)
    .map(
      mediaQuery => css`
        ${theme.media[mediaQuery]} {
          padding-left: ${!variant ? 0 : theme.vars.padding.horizontal[variant][mediaQuery]}px;
          padding-right: ${!variant ? 0 : theme.vars.padding.horizontal[variant][mediaQuery]}px;
        }
      `,
    )

export const getDebugOverlayCss = (props, text, color = null) => {
  if (!props || !props.hasOwnProperty("debug") || props.debug != "true") {
    return css`
      position: relative;
    `
  }
  return css`
    position: relative;
    // border-top: 1px solid white;
    border-right: 1px solid white;
    border-top-right-radius: 30px;
    ${!!color &&
    css`
      background-color: ${color};
    `}

    &::before {
      content: "${text}";
      position: absolute;
      color: black;
      background-color: rgba(255, 255, 255, 0.75);
      font-weight: 700;
      font-size: 8px;
      line-height: 12px;
      letter-spacing: 0em;
      padding: 1px 4px 1px 1px;
      left: 0;
      top: 0;
      border-bottom-right-radius: 10px;
      text-transform: none;
      font-style: normal;
      z-index: 10000;
    }
  `
}
