import React, {Component} from "react"
import PropTypes from "prop-types"
import {connect} from "react-redux"
import {createSelector} from "reselect"
import {injectIntl} from "react-intl"
import isEqual from "lodash/isEqual"
import {Link} from "react-router-dom"
import PageVisibility from "react-page-visibility"
import DocumentTitle from "react-document-title"
import {Route, Switch} from "react-router-dom"
import {ThemeProvider} from "styled-components"
import {CookiesProvider} from "react-cookie"
import styled, {css} from "styled-components" // eslint-disable-line

import * as actions from "../data/actions"
import * as selectors from "../data/selectors"
import HelperPlatform from "../data/utils/HelperPlatform"

import GlobalStyle from "./GlobalStyle"

import SMain from "../view/screens/SMain"
import SCookiePolicy from "../view/screens/SCookiePolicy"
import SNotFound from "../view/screens/SNotFound"

import CVideoLightbox from "../view/components/CVideoLightbox"
import CFooter from "../view/components/CFooter"
import CCookiePopup from "../view/components/CCookiePopup"

import {basicTheme} from "../view/Theme"
import {CONFIG} from "../config"

const DEBUG = false && __DEV__

import {debbify, getObjectDeep as god} from "../data/selectors/helpers"
const debby = (...args) => debbify("AppWithState", ...args)

const DEBUG_MEDIA_QUERY = true && __DEV__

class AppWithState extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    stageDimensions: PropTypes.object,
    mediaQueryClass: PropTypes.string.isRequired,
    location: PropTypes.object.isRequired,
    intlLocale: PropTypes.string,
    tabVisible: PropTypes.bool,
    documentTitle: PropTypes.string,
    locationPathname: PropTypes.string.isRequired,
    analyticsEnabled: PropTypes.bool,
    videoLightboxShown: PropTypes.bool,
    videoLightboxProps: PropTypes.object,
  }

  constructor(props) {
    super(props)
    this.state = {
      drawPlaceholdersBottom: true,
    }
  }

  componentDidMount = async () => {
    const {dispatch} = this.props

    window.addEventListener("resize", this.onWindowResize) // set store's stage dimensions
    this.onWindowResize()

    this.handleProps(this.props, true)
    // methodNotExistingForSentryConfirmation() // eslint-disable-line

    dispatch(actions.requestSitemap())
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  UNSAFE_componentWillReceiveProps = nextProps => this.handleProps(nextProps, false)

  handleProps = (nextProps, initial) => {
    if (initial || this.props.locationPathname != nextProps.locationPathname) {
      // debugger
      window.scrollTo(0, 0)
    }

    // PathData, Page View, Document Lang
    if (initial || this.props.location.pathname != nextProps.location.pathname) {
      this.props.dispatch(actions.requestPostData(nextProps.location.pathname))
      this.props.dispatch(actions.sendAnalyticsPageView(nextProps.location.pathname))
      HelperPlatform.setDocumentLang(nextProps.intlLocale) // for word-wrap
    }
  }

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.onWindowResize)
    clearTimeout(this.timeoutIdDrawPlaceholdersBottom)
  }

  onWindowResize = () => this.props.dispatch(actions.setStageDimensions(window.innerWidth, window.innerHeight))

  handleVisibilityChange = isVisible => {
    const {dispatch, tabVisible} = this.props
    if (tabVisible == isVisible) {
      return
    }
    debby(`handleVisibilityChange()`, {isVisible})
    // this.props.dispatch(actions.sendAnalyticsEvent(`tab_visibility_set_to_${JSON.stringify(isVisible)}`))
    dispatch(actions.setTabVisible(isVisible)) // ADevice
  }

  renderRouterSwitch = () => {
    const {location} = this.props
    debby("renderRouterSwitch()", {location})
    return (
      <Switch location={location}>
        <Route key={`main`} exact path={`/`} component={SMain} />
        <Route key={`cookie-policy`} exact path={`/cookie-policy`} component={SCookiePolicy} />
        <Route component={SNotFound} />
      </Switch>
    )
  }

  onLightboxHideRequest = () => {
    debby("onLightboxHideRequest()")
    this.props.dispatch(actions.hideVideoLightbox())
  }

  onLightboxNextRequest = nextProps => {
    debby("onLightboxNextRequest()", {nextProps})
    this.props.dispatch(actions.showVideoLightbox(nextProps))
  }

  render = () => {
    const {intl, documentTitle, shownInFacebookBrowser, shownInInAppBrowser, analyticsEnabled, videoLightboxShown, videoLightboxProps} = this.props // eslint-disable-line
    const {stageDimensions, postData, tabVisible, mediaQueryClass, locationPathname, intlLocale} = this.props // eslint-disable-line
    debby("render()", {postData, documentTitle, locationPathname})
    return (
      <PageVisibility onChange={this.handleVisibilityChange}>
        <CookiesProvider>
          <ThemeProvider theme={basicTheme}>
            <GlobalStyle />
            {!!documentTitle && <DocumentTitle title={documentTitle} />}
            {this.renderRouterSwitch()}
            <CVideoLightbox
              //
              shown={videoLightboxShown}
              config={videoLightboxProps}
              onHideRequest={this.onLightboxHideRequest}
              enablePlayback={tabVisible}
              onNextRequest={this.onLightboxNextRequest}
            />
            <CFooter />
            <CCookiePopup />
            {DEBUG && (
              <div style={{pointerEvents: "none", position: "fixed", fontSize: 12, left: 0, bottom: 30, backgroundColor: "rgba(255,255,0,0.5)", padding: 10}}>
                <div style={{fontWeight: "bold"}}>AppWithState.js</div>
                <div>location: {JSON.stringify(this.props.location)}</div>
                <div>intlLocale: {JSON.stringify(intlLocale)}</div>
                <Link to={"/"}>home</Link>| <Link to={"/de"}>home (de)</Link>| <Link to={"/yo"}>yo</Link>| <Link to={"/events/20"}>event 20</Link>| <Link to={"/bullshit"}>bullshit 404</Link>
              </div>
            )}
            {/* Top Left: Media Query */}
            {DEBUG_MEDIA_QUERY && (
              <div style={{position: "fixed", left: 0, top: 0, backgroundColor: "rgba(255,255,255,0.5)", fontSize: 12, fontWeight: 700, padding: 5, zIndex: 10000}}>
                {mediaQueryClass.toUpperCase()} {stageDimensions.width}px @ {locationPathname}
              </div>
            )}
            {/* Bottom Right: Analytics Status */}
            {__DEV__ && <div style={{pointerEvents: "none", position: "fixed", right: 0, bottom: 0, backgroundColor: analyticsEnabled ? "rgba(255,255,0,0.5)" : "rgba(0,255,0,0.5)", fontSize: 12, fontWeight: 700, padding: 5, zIndex: 10000}}>ANALYTICS {analyticsEnabled ? "ENABLED" : "DISABLED"}</div>}
          </ThemeProvider>
        </CookiesProvider>
      </PageVisibility>
    )
  }
}

const getDocumentTitle = createSelector([selectors.getPostData], postData => {
  return god(postData, "seoData.title_raw", CONFIG.app.documentTitle)
})

const mapStateToProps = (state, props) => ({
  analyticsEnabled: state.firebase.analyticsEnabled,
  tabVisible: state.device.tabVisible,
  videoLightboxShown: state.routing.videoLightboxShown,
  videoLightboxProps: state.routing.videoLightboxProps,
  postData: selectors.getPostData(state, props),
  documentTitle: getDocumentTitle(state, props),
  locationPathname: selectors.getLocationPathname(state, props),

  // DEBUG
  intlLocale: DEBUG ? selectors.getIntlLocale(state, props) : "en",
  stageDimensions: DEBUG || DEBUG_MEDIA_QUERY ? state.device.stageDimensions : {width: 1, height: 1},
  mediaQueryClass: DEBUG || DEBUG_MEDIA_QUERY ? selectors.getMediaQueryClass(state, props) : "-",
})
export default injectIntl(connect(mapStateToProps)(AppWithState))
