import {createSelector} from "reselect"
import {CONFIG} from "../../config"

export const getIntl = (state, props) => props.intl // eslint-disable-line
export const getIntlLocale = state => state.intl.locale
export const getLangs = () => CONFIG.intl.langs
export const getIntlMessages = state => state.intl.messages

export const getOtherLocales = createSelector([getIntlLocale], intlLocale => CONFIG.intl.langs.filter(lang => lang != intlLocale))

export const getLocale = state => {
  const {locale} = state.intl
  switch (locale) {
    case "en":
      return "en-GB"
    case "es":
      return "es-ES"
    case "ca":
      return "ca-CA"
  }
  return "en-GB"
}
