import React, {Component} from "react"
import PropTypes from "prop-types"
import isEqual from "lodash/isEqual"
import {connect} from "react-redux"
import styled, {css, ThemeProvider} from "styled-components" // eslint-disable-line

import * as selectors from "../../data/selectors"
import {CONFIG} from "../../config" // eslint-disable-line

const DEBUG = false && __DEV__
import {debbify, getObjectDeep as god} from "../../data/selectors/helpers"
const debby = (...args) => DEBUG && debbify("CLogo", ...args)

const BYPASS_REFRESH_COLOR = false && __DEV__

const COLOR_DARK_BG = "white"
const COLOR_LIGHT_BG = CONFIG.layout.colors.jaguar

class CLogo extends Component {
  static propTypes = {
    color: PropTypes.string,
    screen: PropTypes.oneOf(["main", "cookie-policy"]),
    offsetY: PropTypes.number, // constant added to windowScrollY
    // connect
    elementsY: PropTypes.object, // {[screen]: {[section]: y}}
  }
  static defaultProps = {
    color: COLOR_DARK_BG,
    offsetY: 40,
  }

  constructor(props) {
    super(props)
    this.state = {
      color: props.color,
    }
  }

  componentDidMount = () => {
    this.refreshLogoColorIntervalId = setInterval(this.refreshLogoColor, 300)
  }

  componentWillUnmount = () => {
    clearInterval(this.refreshLogoColorIntervalId)
  }

  refreshLogoColor = () => {
    if (BYPASS_REFRESH_COLOR) {
      return
    }

    const {screen, offsetY, elementsY} = this.props
    const windowScrollY = selectors.getWindowScrollY() + offsetY
    const elementsYScreen = god(elementsY, screen)

    DEBUG && debby("refreshLogoColor()..", {offsetY, windowScrollY})

    let lastSection = undefined // above current scroll position / viewport
    let lastTopElementY = 0

    if (!!elementsYScreen && !!Object.keys(elementsYScreen).length) {
      for (const section of Object.keys(elementsYScreen)) {
        DEBUG && console.log(`   checking section '${section}' (y: ${elementsYScreen[section]}, lastTopElementY: ${lastTopElementY})`)
        if (windowScrollY > lastTopElementY && windowScrollY >= elementsYScreen[section]) {
          lastTopElementY = elementsYScreen[section]
          lastSection = section
        }
      }
    }

    let color = this.props.color

    if (screen == "main") {
      switch (lastSection) {
        case "hero":
        case "projects":
          color = COLOR_DARK_BG
          break
        case "first":
        case undefined:
          color = COLOR_LIGHT_BG
          break
        default: {
          if (__DEV__) {
            throw new Error(`CLogo.refreshLogoColor(): Unhandled lastSection '${lastSection}'.`)
          }
        }
      }
    } else if (screen == "cookie-policy") {
      color = COLOR_LIGHT_BG
    } else {
      // __DEV__ && console.error(`CLogo.refreshLogoColor(): Unhandled screen '${screen}'.`)
      if (__DEV__) {
        throw new Error(`CLogo.refreshLogoColor(): Unhandled screen '${screen}'.`)
      }
    }

    this.setState({color})

    debby("refreshLogoColor() done.", {screen, windowScrollY: Math.round(windowScrollY), lastSection, color, elementsYScreen})
    DEBUG && console.log("")
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  render = () => {
    const {color} = this.state
    const d = {debug: DEBUG.toString()}
    debby("render()", {color, d})
    return (
      <Container {...d}>
        <LogoSvg style={{width: 40, overflow: "visible"}} version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 231 250">
          <defs>
            <filter id="f3" x="0" y="0" width="200%" height="200%">
              <feOffset result="offOut" in="SourceAlpha" dx="0" dy="0" />
              <feGaussianBlur result="blurOut" in="offOut" stdDeviation="10" />
              <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
            </filter>
          </defs>
          <g /*filter="url(#f3)"*/>
            <path fill={color} d="M224.54,0.01l0-0.01H30.11c0,0-3.36,0-5.11,0C11.19,0,0,11.19,0,25s11.19,25,25,25c1.75,0,5.11,0,5.11,0H135 c2.76,0,5,2.24,5,5c0,1.76-0.58,3.11-2.23,4.32S19.63,146.22,19.63,146.22c-4.14,2.76-4.14,4.65-4.14,6.58 c0,4.04,3.27,7.31,7.31,7.31c2.07,0,3.93-0.86,5.26-2.24l200.17-146.6c1.45-1.15,2.37-2.98,2.37-4.98 C230.59,2.9,227.9,0.14,224.54,0.01z" />
            <path fill={color} d="M6.06,249.98l0,0.01h194.42c0,0,3.36,0,5.11,0c13.81,0,25-11.19,25-25s-11.19-25-25-25c-1.75,0-5.11,0-5.11,0 H95.59c-2.76,0-5-2.24-5-5c0-1.76,0.58-3.11,2.23-4.32s118.14-86.89,118.14-86.89c4.14-2.76,4.14-4.65,4.14-6.58 c0-4.04-3.27-7.31-7.31-7.31c-2.07,0-3.93,0.86-5.26,2.24L2.37,238.71C0.92,239.87,0,241.69,0,243.69 C0,247.09,2.69,249.85,6.06,249.98z" />
          </g>
        </LogoSvg>
      </Container>
    )
  }
}

const Container = styled.div`
  display: flex;
  alignitems: center;
  justify-content: center;
  overflow: visible;
  pointer-events: auto;

  border: ${props => (props.debug == "true" ? 1 : 0)}px solid red;

  // transform: translate3d(60px, 0px, 0px);

  width: 50px;
  height: 50px;
  ${props => css`
    ${props.theme.media.smdown} {
      width: 20px;
      height: 20px;
    }
  `}
`

const LogoSvg = styled.svg`
  filter: drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.3));
  transition: all 1s;
  // opacity: 0.5;

  g {
    path {
      // opacity: 0.2;
      transition: all 1s;
    }
  }
`

const mapStateToProps = state => ({
  elementsY: state.layout.elementsY,
})

export default connect(mapStateToProps)(CLogo)
