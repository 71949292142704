import React from "react"
import ReactDOM from "react-dom"
import(/* webpackPreload: true */ "./styles/index.scss")

import reportWebVitals from "./reportWebVitals"

import App from "./App"
import BugReportManager from "./data/utils/BugReportManager"
import {CONFIG} from "./config"
import {version as packageVersion} from "../package.json"

if (!__DEV__) {
  console.log = () => {}
  console.warn = () => {}
}

global.DEBUG_STYLE = true

BugReportManager.init({appName: "zoomsport", appVersion: packageVersion, persistKey: CONFIG.redux.storePersistKey})
ReactDOM.render(<App />, document.getElementById("root"))

reportWebVitals()
